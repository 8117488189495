import { useEffect, useState } from "react";

const useFilters = (fetchDataFunc: any) => {
  const [filters, setFilters] = useState({
    sortColumnNumber: 0,
    sortTypeNumber: 1,
  });

  const onChangeRadioHandler = (e: any) => {
    const { value } = e.target;
    setFilters({ ...filters, sortColumnNumber: parseInt(value) });
  };

  const handleAscendingSorting = () => {
    setFilters({ ...filters, sortTypeNumber: 1 });
  };

  const handleDescendingSorting = () => {
    setFilters({ ...filters, sortTypeNumber: 2 });
  };

  useEffect(() => {
    const { sortTypeNumber, sortColumnNumber } = filters;
    if (
      sortTypeNumber !== undefined &&
      sortTypeNumber !== 0 &&
      sortColumnNumber !== undefined &&
      sortColumnNumber !== 0
    ) {
      fetchDataFunc({ sortColumnNumber, sortTypeNumber });
    }
  }, [filters]);

  const clearFilters = () => {
    setFilters({
      sortColumnNumber: 0,
      sortTypeNumber: 1,
    });
    fetchDataFunc({});
  };

  return {
    filters,
    handleAscendingSorting,
    handleDescendingSorting,
    onChangeRadioHandler,
    clearFilters,
  };
};

export default useFilters;
