import { Fragment } from "react";
import CustomRadio from "../CustomRadio";
import DropDownMenu from "./DropDownMenu";

export type FilterType = {
  value: string;
  label: string;
  name: string;
  onChangeHandler: (e: any) => void;
  checked: boolean;
};

type Props = {
  clearFilters?: () => void;
  handleAscendingSorting?: () => void;
  handleDescendingSorting?: () => void;
  onChangeRadioHandler?: (e: any) => void;
  filters?: {
    sortColumnNumber: number;
    sortTypeNumber: number;
  };
  radios: FilterType[];
};

const FiltersComponent = ({
  clearFilters,
  handleAscendingSorting,
  handleDescendingSorting,
  onChangeRadioHandler,
  filters,
  radios,
}: Props) => {
  const { sortColumnNumber, sortTypeNumber } = filters ?? {};
  return (
    <DropDownMenu
      clearFilters={clearFilters}
      sortColumnNumber={sortColumnNumber ?? 0}
      sortTypeNumber={sortTypeNumber ?? 0}
      handleAscendingSorting={handleAscendingSorting}
      handleDescendingSorting={handleDescendingSorting}
    >
      <Fragment>
        {radios.map((radio) => (
          <CustomRadio
            key={radio.value}
            value={radio.value}
            label={radio.label}
            name={radio.name}
            onChangeHandler={onChangeRadioHandler}
            checked={radio.checked}
          />
        ))}
      </Fragment>
    </DropDownMenu>
  );
};

export default FiltersComponent;
