import greySearch from "../../../assets/icons/grey_search.png";
import { CustomSearchProps } from "../../Types";

const CustomSearch: React.FC<CustomSearchProps> = ({
  icon = greySearch,
  width = "w-full",
  handleSearchChange,
  value
}) => {
  return (
    <div className={`relative w-4/5 `}>
      <input
        value={value}
        type="text"
        name="hs-table-search"
        id="hs-table-search"
        className={`py-2 px-3 ps-12 bg-white block border border-[#E3E5E9] ${width} rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 `}
        style={{backgroundColor:"white" , color:"black"}}
        placeholder="Search"
        onChange={(e) => {
          if (handleSearchChange) {
            handleSearchChange(e);
          }
        }}
      />
      <div className="absolute inset-y-0 start-1 flex items-center pointer-events-none ps-3">
        <span className="text-grey mr-4">
          <img src={icon} alt="search_icon" />
        </span>
      </div>
    </div>
  );
};

export default CustomSearch;
