import { useEffect, useState } from "react";

interface TableProps {
  columns: any[];
  data: any[];
  getSelectedData?: (selectedRows: any[]) => void;
  getSelectedRow?: (id: string, data: any, row?: any) => void;
  disableCheckBoxes?: any[];
  disableHeaderCheckbox?: boolean;
}

interface CheckBoxProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const Checkbox: React.FC<CheckBoxProps> = ({ checked, onChange, disabled }) => (
  <input
    type="checkbox"
    checked={checked}
    onChange={onChange}
    disabled={disabled}
    className="form-checkbox h-3 w-3 text-indigo-600 cursor-pointer"
  />
);

const Table: React.FC<TableProps> = ({
  columns,
  data,
  getSelectedData,
  getSelectedRow,
  disableCheckBoxes = [],
  disableHeaderCheckbox = false,
}) => {
  const [selectedRows, setSelectedRows] = useState<any>([]);

  const handleCheckboxChange = (e: any, row: any) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedRows([...selectedRows, row]);
    } else {
      const result = selectedRows.filter(
        (selectedRow: any) => selectedRow._id !== row._id
      );
      setSelectedRows(result);
    }
  };

  useEffect(() => {
    if (getSelectedData) {
      getSelectedData(selectedRows);
    }
  }, [selectedRows]);

  useEffect(() => {
    setSelectedRows([]);
  }, [data]);

  return (
    <table className="min-w-full divide-y divide-gray-200 mt-4">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            <Checkbox
              disabled={disableHeaderCheckbox}
              checked={
                data?.length > 0 && selectedRows?.length === data?.length
              }
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedRows(data);
                } else {
                  setSelectedRows([]);
                }
              }}
            />
          </th>
          {columns.map((column: any, index: any) => (
            <th
              key={column.name}
              scope="col"
              className="px-6 py-3 text-left text-base font-medium text-gray-500 tracking-wider"
            >
              {column.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {data && data.length > 0 ? (
          data.map((row: any) => (
            <tr key={row._id} className="cursor-pointer">
              <td className="px-6 whitespace-nowrap">
                <Checkbox
                  disabled={disableCheckBoxes.includes(row._id)}
                  checked={
                    selectedRows &&
                    selectedRows.length > 0 &&
                    selectedRows.some(
                      (selectedRow: any) => selectedRow._id === row._id
                    )
                  }
                  onChange={(e) => handleCheckboxChange(e, row)}
                />
              </td>
              {columns.map((column: any, index: any) => (
                <td
                  onClick={() => {
                    if (getSelectedRow) {
                      getSelectedRow(column.name, data, row);
                    }
                  }}
                  key={column.name}
                  className="px-6 py-2 whitespace-nowrap"
                >
                  {column.selector
                    ? column.selector(row)
                    : row[column.field] ?? "N/A"}
                </td>
              ))}
            </tr>
          ))
        ) : (
          <tr>
            <td
              colSpan={columns.length + 1}
              className="px-6 py-4 text-center text-gray-500"
            >
              No data available
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Table;
