import React, { useRef } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getCommonChartOptions } from "./getCommonChartOptions";
import { GraphProps } from "../Tutor/Graph";

Highcharts.setOptions({
  credits: {
    enabled: false,
  },
});

const Graph: React.FC<GraphProps> = ({
  title,
  series,
  tickInterval,
  min,
  max,
  categories,
  legendEnabled,
  ...props
}) => {
  const options: Highcharts.Options = {
    ...getCommonChartOptions(
      title,
      legendEnabled,
      categories,
      min,
      max,
      tickInterval,
      series
    ),
    chart: {
      type: "spline",
      scrollablePlotArea: {
        minWidth: categories.length < 7 ? 100 : 1200,
        scrollPositionX: 1,
      },
      marginBottom: 80,
      height: "30%",
    },
  };
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  return (
    <div className="w-full">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
        {...props}
      />
    </div>
  );
};

export default Graph;
