import CustomButton from "../../Common/CustomButton";
import CustomTextField from "../../Common/CustomTextField";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setName,
  setEmail,
  closeModal,
  setMobileNumber,
} from "../../../app/Slices/modalSlice";
import {
  useCreateTutorMutation,
  useValidateUserMutation,
  useAllTutorsQuery,
} from "../../../api/institutionTutorApi";
import { RootState } from "../../../app/Store";
import {
  setEmailError,
  setMobileNumberError,
} from "../../../app/Slices/authSlice";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import "../../Students/AddStudentsInBulk/dropDown.css";
import { capitalizeFirstLetter } from "../../../utils/utils";

const successToastStyle = {
  color: "#292D35",
  fontSize: "12px",
};

const additionalMessageStyle = {
  fontStyle: "italic",
  marginTop: "2px",
  color: "#737E93",
  fontSize: "10px",
};

const AddNewTutorForm = () => {
  const { refetch } = useAllTutorsQuery({});
  const emailPattern = /^[a-zA-Z0-9][^\s@]*@[^\s@]+\.[a-zA-Z]+$/;
  const name = useSelector((state: RootState) => state.root.modal.name);
  const email = useSelector((state: RootState) => state.root.modal.email);
  const mobileNumber = useSelector(
    (state: RootState) => state.root.modal.mobileNumber
  );
  const dispatch = useDispatch();
  const { emailError, mobileNumberError } = useSelector(
    (state: RootState) => state.root.auth
  );
  const [createTutor, { isLoading: createTutorLoading }] =
    useCreateTutorMutation();
  const [validateUser, { isLoading }] = useValidateUserMutation();

  const onClickHandler = async () => {
    try {
      if (email && !emailPattern.test(email)) {
        dispatch(setEmailError("Please Enter Valid Email Ex:abc@domain.com"));
      } else if (mobileNumber && !isValidPhoneNumber("+" + mobileNumber)) {
        dispatch(setMobileNumberError("Please Enter Valid Mobile Number"));
      } else {
        dispatch(setEmailError(""));
        dispatch(setMobileNumberError(""));
        const validateUserResponse = await validateUser({
          emailId: email,
          mobileNumber: "+" + mobileNumber,
        }).unwrap();
        if (validateUserResponse?.userexists) {
          toast.error(<div>{validateUserResponse?.message}</div>);
        } else {
          const res = await createTutor({
            email: email,
            name: capitalizeFirstLetter(name),
            mobileNumber: "+" + mobileNumber,
            isOrg: true,
          });

          if ("error" in res && res.error && "data" in res.error) {
            toast.error(
              <div style={successToastStyle}>Unable to add tutor</div>,
              {
                autoClose: 5000,
              }
            );
          } else {
            refetch();
            dispatch(closeModal());
            toast.success(
              <>
                <div style={successToastStyle}>Invite Sent Successfully</div>
                <div style={additionalMessageStyle}>Invite to {name} sent</div>
              </>,
              {
                autoClose: 5000,
              }
            );
          }
        }
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="px-6">
        <CustomTextField
          placeholder="Name"
          type="text"
          label="Name"
          onChangeHandler={(e) => {
            dispatch(setName(e.target.value));
          }}
          value={name}
          mandatory={true}
        />
        {/* <CustomTextField
          placeholder="Mobile Number"
          type="text"
          label="Mobile Number"
          onChangeHandler={(e)=>{
            dispatch(setMobileNumber(e.target.value))
          }}
          value={mobileNumber}
        /> */}
        <div className="mt-[13px] mb-[13px]">
          <div className="text-sm mb-1 text-primaryColor">
            {" "}
            Mobile Number
            <span className="text-red-600 text-base font-normal">*</span>
          </div>
          <div className="w-full">
            <PhoneInput
              containerClass="w-full"
              inputClass="border border-gray-300 rounded-2 p-2 focus:outline-none focus:border-black w-[381px] h-[41px]"
              country={"in"}
              value={mobileNumber}
              onChange={(value) => {
                dispatch(setMobileNumber(value));
                if (mobileNumber && isValidPhoneNumber("+" + mobileNumber)) {
                  dispatch(setMobileNumberError(""));
                }
              }}
            />
          </div>
        </div>
        <CustomTextField
          placeholder="Email"
          type="text"
          label="Email"
          onChangeHandler={(e) => {
            dispatch(setEmail(e?.target?.value?.toLowerCase()));
            if (email && emailPattern.test(email)) {
              dispatch(setEmailError(""));
            }
          }}
          value={email}
          mandatory={true}
        />
      </div>
      <p className="text-red-600 text-base font-normal mt-6 ml-4">
        {emailError}
      </p>
      <p className="text-red-600 text-base font-normal mt-6 ml-4">
        {mobileNumberError}
      </p>
      <div className="mt-4 flex justify-center border-t border-t-[#E3E5E9] py-4">
        <div className="px-6 w-full">
          <CustomButton
            disabled={
              !name ||
              !email ||
              !mobileNumber ||
              isLoading ||
              createTutorLoading
            }
            name="SEND INVITE"
            onClickHandler={onClickHandler}
            width="w-full"
          />
        </div>
      </div>
    </>
  );
};

export default AddNewTutorForm;
