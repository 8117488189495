import { useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useAppSelector } from "../../../../app/hooks";
import { getItem } from "../../../../utils/storage";
import ReportProgress from "../../../Common/ReportProgress";
import CommonTab from "../../../Common/Tab/CommonTab";
import BandDescription from "../../../Common/BandDescription";

const ReadingTestResults = () => {
  const {
    getReadingTestScorePartOne,
    getReadingTestScorePartTwo,
    getReadingTestScorePartThree,
  } = useAppSelector((state) => state.root.readingResults);
  const partType = getItem("testType");
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      label: "Part 1",
      testType:'part1',
      content: (
        <div>
          <ReportProgress partDetails={getReadingTestScorePartOne} />
        </div>
      ),
    },
    {
      label: "Part 2",
      testType:'part2',
      content: (
        <div>
          <ReportProgress partDetails={getReadingTestScorePartTwo} />
        </div>
      ),
    },
    {
      label: "Part 3",
      testType:'part3',
      content: (
        <div>
          <ReportProgress partDetails={getReadingTestScorePartThree} />
        </div>
      ),
    },
  ];

  return (
    <div className="w-full grid grid-cols-3 gap-4">
      <div className="border-2 rounded-[8px] bg-white">
        <header>
          <CommonTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabs={tabs}
            partType={partType}
          />
        </header>
      </div>

      {((partType === "full" && activeTab === 0) || partType === "part1") && (
        <BandDescription
          title="Band Description"
          skillLevel={getReadingTestScorePartOne?.skill_level}
          overallScore={getReadingTestScorePartOne?.overall_score}
          description={getReadingTestScorePartOne?.description}
        />
      )}
      {((partType === "full" && activeTab === 1) || partType === "part2") && (
        <BandDescription
          title="Band Description"
          skillLevel={getReadingTestScorePartTwo?.skill_level}
          overallScore={getReadingTestScorePartTwo?.overall_score}
          description={getReadingTestScorePartTwo?.description}
        />
      )}

      {((partType === "full" && activeTab === 2) || partType === "part3") && (
        <BandDescription
          title="Band Description"
          skillLevel={getReadingTestScorePartThree?.skill_level}
          overallScore={getReadingTestScorePartThree?.overall_score}
          description={getReadingTestScorePartThree?.description}
        />
      )}
    </div>
  );
};

export default ReadingTestResults;
