import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Tutor, TutorState } from "../../components/Types";
import { institutionTutorApi } from "../../api/institutionTutorApi";

const initialState: TutorState = {
  selectedRows: {
    studentIds: [],
    tutorId: "",
    studentNames: [],
  },
  selectedTutorRows: [],
  assignedAndUnassignedStudents: [],
  value: 0,
  reAssign: false,
  testId: null,
  studentsList: [],
  tutorsList: [],
  tutorsListReAssign: [],
  assignStudentsClick: false,
  selectedTutorId: "",
  selectedTutorDetails: {
    _id: "",
    name: "",
    noOfStudentsAssigned: "",
    noOfTestsGraded: "",
    noOfTestsPending: "",
  },
  selectedStudentDetails: {
    _id: "",
    name: "",
  },
  tenDayActivityReport: {},
  gradedTests: [],
};

const tutorSlice = createSlice({
  name: "tutor",
  initialState,
  reducers: {
    setSelectedTutor: (state, action) => {
      state.selectedTutorId = action.payload;
    },
    setSelectedTutorDetails: (state, action: PayloadAction<Tutor>) => {
      state.selectedTutorDetails = action.payload;
    },
    setAssignedAndUnassignedStudents: (state, action) => {
      state.assignedAndUnassignedStudents = action.payload;
    },
    setTestId: (state, action) => {
      state.testId = action.payload;
    },
    setAssignStudents: (state, action) => {
      state.assignStudentsClick = action.payload;
    },
    setReassign: (state, action) => {
      state.reAssign = action.payload;
    },
    setTutorListReAssign: (state, action) => {
      state.tutorsListReAssign = action.payload;
    },
    setSelectedRows: (state, action) => {
      state.selectedRows.studentIds = action.payload.studentIds;
      state.selectedRows.tutorId = action.payload.tutorId;
      state.selectedRows.studentNames = action.payload.studentNames;
      // state.selectedRows = action.payload
    },
    setSelectedTutorRows: (state, action) => {
      state.selectedTutorRows = action.payload;
    },
    resetTutorState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        institutionTutorApi.endpoints.allTutors.matchFulfilled,
        (state, action) => {
          state.tutorsList = action.payload;
          state.tutorsListReAssign = action.payload;
        }
      )
      .addMatcher(
        institutionTutorApi.endpoints.assignedAndUnassignedStudents
          .matchFulfilled,
        (state, action) => {
          state.studentsList = action.payload;
        }
      )
      .addMatcher(
        institutionTutorApi.endpoints.get10DayActivityByTutorId.matchFulfilled,
        (state, action) => {
          state.tenDayActivityReport = action.payload;
        }
      )
      .addMatcher(
        institutionTutorApi.endpoints.fetchGradedTests.matchFulfilled,
        (state, action) => {
          state.gradedTests = action.payload;
        }
      );
  },
});

export const {
  setSelectedTutor,
  setSelectedTutorDetails,
  setAssignedAndUnassignedStudents,
  setTestId,
  setTutorListReAssign,
  setReassign,
  setAssignStudents,
  resetTutorState,
  setSelectedRows,
  setSelectedTutorRows,
} = tutorSlice.actions;
export default tutorSlice.reducer;
