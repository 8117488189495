import { useState, useEffect } from "react";

const useProgressBarStyles = (partDetails: any) => {
  const [percentage, setPercentage] = useState(0);
  const [pathColor, setPathColor] = useState("#3FC7FA");
  const [trailColor, setTrailColor] = useState("#d6d6d6");
  const [textColor, setTextColor] = useState("black");
  const [fraction, setFraction] = useState<string>("0");

  useEffect(() => {
    if (partDetails) {
      const value = (partDetails.overall_score / 9) * 100;
      setPercentage(value);
      if (value === 0 || (value >= 0 && value <= 50)) {
        setPathColor("#FF495C");
        setTrailColor("#FFC2B6");
        setTextColor("#FF495C");
      } else if (value > 50 && value <= 77) {
        setPathColor("#FFBB28");
        setTrailColor("#FFECA9");
        setTextColor("#FFBB28");
      } else if (value > 77 && value <= 100) {
        setPathColor("#59A51A");
        setTrailColor("#DCF8A7");
        setTextColor("#59A51A");
      }
      setFraction(partDetails?.overall_score);
    } else setFraction("0");
  }, [partDetails]);

  return { percentage, pathColor, trailColor, textColor, fraction };
};

export default useProgressBarStyles;
