import { useEffect } from "react";

const Tab = ({
  label,
  onClick,
  isActive,
}: {
  label: string;
  onClick: () => void;
  isActive: boolean;
}) => {
  return (
    <button
      className={`cursor-pointer px-4 py-2 border-b-[5px] rounded-tl-10 rounded-tr-10 rounded-b-0 font-extrabold ${
        isActive ? "border-red-600 text-[#E31837]" : "border-transparent"
      }`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

const CommonTab = ({
  tabs,
  partType,
  setActiveTab,
  activeTab,
}: {
  tabs: any[];
  partType: string;
  setActiveTab: (index: number) => void;
  activeTab: number;
}) => {
  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };
  const filteredTabs = tabs.filter((tab) => tab.testType === partType);

  const tabIndex = activeTab;

  useEffect(() => {
    setActiveTab(tabIndex);
  }, [partType, tabIndex, setActiveTab]);

  return (
    <div>
      <div className="flex border-b border-b-[#E3E5E9] rounded-t-[8px] pt-4 pl-4 bg-[#F8F9FA]">
        {tabs
          ?.filter((item) => item.testType === partType)
          ?.map((tab, index) => (
            <Tab
              key={partType}
              label={tab.label}
              onClick={() => handleTabClick(index)}
              isActive={index === tabIndex}
            />
          ))}
        {partType === "full" &&
          tabs?.map((tab, index) => (
            <Tab
              key={partType}
              label={tab.label}
              onClick={() => handleTabClick(index)}
              isActive={index === tabIndex}
            />
          ))}
      </div>
      <div>
        {partType !== "full"
          ? filteredTabs[tabIndex].content
          : tabs[tabIndex].content}
      </div>
    </div>
  );
};

export default CommonTab;
