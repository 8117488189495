import { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { calculateFraction } from "../../../utils/reusableFunctions";

const CircularProgressBar = (props: any) => {
  const [fraction, setFraction] = useState(0);
  const totalData = props?.score?.map((item: any) => item);
  const totalScore = totalData?.reduce(
    (accumulator: any, currentValue: any) => accumulator + currentValue,
    0
  );
  const totalScoreLength = totalData?.length;
  const averageScore = totalScoreLength ? totalScore / totalScoreLength : 0;

  useEffect(() => {
    const value = calculateFraction(averageScore);
    setFraction(value);
  }, [averageScore]);

  let percentage = (averageScore / 9) * 100; // Calculate the percentage (5/9 * 100)

  let pathColor = "#3FC7FA"; // Default path color
  let trailColor = "#d6d6d6";
  let textColor = "black";

  if (percentage === 0) {
    pathColor = "#FF495C"; // Red for 0 percentage
    trailColor = "#FFC2B6";
    textColor = "#FF495C";
  } else if (percentage >= 0 && percentage <= 50) {
    pathColor = " #FF495C";
    trailColor = "#FFC2B6";
    textColor = "#FF495C";
  }
  if (percentage > 50 && percentage <= 77) {
    pathColor = "#FFBB28";
    trailColor = "#FFECA9";
    textColor = "#FFBB28";
  }
  if (percentage > 77 && percentage <= 100) {
    pathColor = "#59A51A";
    trailColor = "#DCF8A7";
    textColor = "#59A51A";
  }
  return (
    <div className="h-full ml-[0rem] flex items-center justify-center mt-[0rem]">
      <CircularProgressbar
        value={percentage}
        text={`${fraction}/9`}
        styles={buildStyles({
          rotation: 1,
          strokeLinecap: "round",
          textSize: "26px",
          pathTransitionDuration: 0.5,
          pathColor: pathColor,
          textColor: textColor,
          trailColor: trailColor,
        })}
      />
    </div>
  );
};

export default CircularProgressBar;
