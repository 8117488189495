import { useEffect, useRef, useState } from "react";
import CustomDropDown from "../Common/CustomDropdown";
import CustomButton from "../Common/CustomButton";
import { toast } from "react-toastify";
import {
  useAllTutorsQuery,
  useLazyAllGroupingStudentsQuery,
} from "../../api/institutionTutorApi";
import { setTutorId } from "../../app/Slices/studentSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/Store";
import axios from "axios";
import { genericHeaders, serviceUrl } from "../../utils/apiConfig";
import { saveAs } from "file-saver";
import { closeModal } from "../../app/Slices/modalSlice";
import { useAppSelector } from "../../app/hooks";
import { getItem } from "../../utils/storage";
import { read, write } from "xlsx";

function ImportData() {
  const [selectedFileName, setSelectedFileName] = useState("");
  const { isOrg } = useAppSelector((state) => state.root.auth);
  const roles = getItem("roles");
  const userId = getItem("userId");
  const [selectType, setSelectType] = useState();
  const { data, refetch } = useAllTutorsQuery({});
  const [allGroupingStudents] = useLazyAllGroupingStudentsQuery();
  const [file, setFile] = useState<File | null | undefined>(undefined);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { tutorId } = useSelector((state: RootState) => state.root.student);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleSelect = (value: string) => {
    const selectedOption = data?.find((option: any) => option.name === value);
    dispatch(setTutorId(selectedOption._id));
  };
  useEffect(() => {
    refetch();
  }, []);
  const handleUpload = (event: any) => {
    const file = event.target.files[0];
    setSelectedFileName(file ? file.name : "");
    setFile(file);
    const fileType = file?.type;
    setSelectType(fileType);
    if (
      fileType !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      toast.error("Please Upload file in the format of xlsx only");
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const handleUploadImportData = async () => {
    if (
      selectType ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      let payload = {
        file: file,
      };
      let apiUrl =
        isOrg && roles?.includes("ADMIN")
          ? `${serviceUrl}/api/student/bulkstudentimport?tutorId=${tutorId}`
          : `${serviceUrl}/api/student/bulkstudentimport?tutorId=${userId}`;

      let response: any;
      try {
        setIsLoading(true);
        response = await axios.post(apiUrl, payload, {
          headers: {
            ...genericHeaders(),
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob",
        });

        if (response.status === 200) {
          const blob = new Blob([response?.data]);
          const fileReader = new FileReader();
          fileReader.onload = function (event) {
            if (event.target) {
              const data = new Uint8Array(event.target.result as ArrayBuffer);
              const workbook = read(data, { type: "array" });
              const wbout = write(workbook, {
                bookType: "xlsx",
                type: "array",
              });
              const filename = "studentData.xlsx";
              const blob = new Blob([wbout], {
                type: "application/octet-stream",
              });
              saveAs(blob, filename);
            }
          };

          fileReader.readAsArrayBuffer(blob);
          setIsLoading(false);
          dispatch(closeModal());
          allGroupingStudents({});
          toast.success("Uploaded Document Successfully");
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        toast.error("Unable to upload document");
        console.error("Error downloading file:", err);
      }
    }
  };

  const handleDownload = async () => {
    const s3Url =
      "https://cambri.s3.ap-south-1.amazonaws.com/Employee-Letter-Docs/Import_Student_Tutor.xlsx";
    try {
      const response = await fetch(s3Url);
      const blob = await response.blob();
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      const urlParts = s3Url.split("/");
      const filename = urlParts[urlParts.length - 1];
      downloadLink.setAttribute("download", filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <>
      <div className="felx flex-col">
        {isOrg && roles?.includes("ADMIN") && (
          <>
            <p className="flex justify-center mb-2 mt-4 font-semibold">
              Select Tutor :
            </p>
            <CustomDropDown
              options={data}
              onSelect={handleSelect}
              defaultValue="UnAssigned"
            />
          </>
        )}
        <button
          onClick={() => {
            handleDownload();
          }}
          className=" block m-auto text-secondaryColor text-sm underline text-center mb-4 mt-2"
        >
          Click here for download sample file
        </button>
        <div className="block m-auto mb-4 ml-48">
          <div className="mb-[1.5rem] -ml-[5rem]">
            {selectedFileName && <p>Selected file: {selectedFileName}</p>}
          </div>
          <label
            htmlFor="file-upload"
            className="cursor-pointer bg-blue-500 text-white py-2 px-4 rounded"
          >
            Choose File
          </label>
          <input
            id="file-upload"
            ref={fileInputRef}
            className="hidden"
            type="file"
            onChange={handleUpload}
          />
        </div>
      </div>
      <div className="mt-4 flex justify-center border-t border-t-[#E3E5E9] py-4">
        <div className="px-6 w-full">
          <CustomButton
            name="Upload File"
            onClickHandler={() => {
              handleUploadImportData();
            }}
            width="w-full"
            disabled={
              selectType !==
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
              isLoading
            }
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
}

export default ImportData;
