interface ILinearProgressBar {
  score: any;
  parameter?: string;
  key?: number;
}

export const LinerProgressBar = ({
  score,
  parameter,
  key,
}: ILinearProgressBar) => {
  let average;
  let percentage;
  let name;
  let outerbgcolor;
  let innerbgcolor;

  if (score || score === 0) {
    average = score;
    percentage = (average / 9) * 100;
    name = parameter;
  }
  if (percentage || percentage === 0) {
    if (percentage >= 0 && percentage <= 50) {
      outerbgcolor = "#FFE4DA";
      innerbgcolor = "#FF495C";
    }
    if (percentage > 50 && percentage <= 77.77) {
      outerbgcolor = "#FFBB28";
      innerbgcolor = "#FFBB28";
    }
    if (percentage > 77.77 && percentage <= 100) {
      outerbgcolor = " #DCF8A7";
      innerbgcolor = "#59A51A";
    }
  }

  return (
    <>
      <div
        key={key}
        className="flex flex-row justify-between text-[#4E5664] font-semibold"
      >
        <p>{name}</p>
        <p>{average}/9</p>
      </div>
      <div
        className="w-full h-[8px]  rounded-2xl"
        style={{
          backgroundColor: outerbgcolor,
        }}
      >
        <div
          className=" h-full rounded-2xl"
          style={{
            backgroundColor: innerbgcolor,
            width: `${percentage}%`,
          }}
        ></div>
      </div>
    </>
  );
};
