import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Signup from "../../pages/Signup";
import VerifyUser from "../../pages/VerifyUser";
import Otp from "../../pages/otp";
import Dashboard from "../../pages/Dashboard";
import Tutors from "../../pages/Tutors";
import Students from "../../pages/Students";
import EditorTutor from "../Editor/editortutor";
import AuthenticatedLayout from "../AuthenticatedLayout";
import OnBoardingScreen from "../../pages/OnBoardingScreen";
import SpeakingTestResultsMain from "../../pages/SpeakingTestResultsMain";
import AccountSettings from "../../pages/AccountSettings";
import { useAppSelector } from "../../app/hooks";
import { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { getItem } from "../../utils/storage";
import NotFoundPage from "../Common/NotFoundPage";
import ReadingTestResultsMain from "../../pages/ReadingTestResultsMain";
import Performance from "../../pages/Performance";
import ListeningTestResultsMain from "../../pages/ListeningTestResultsMain";
import WritingTestResultsMain from "../../pages/WritingTestResultsMain";

const Routing = () => {
  const { isLoggedIn } = useAppSelector((state) => state.root.auth);
  const location = useLocation();
  const token = getItem("token");
  const navigate = useNavigate();
  let roles: any[] = [];

  if (token) {
    const decoded = jwtDecode(token);
    roles = (decoded as { roles: string[] }).roles;
  }

  useEffect(() => {
    const getRedirectPath = () => {
      if (isLoggedIn) {
        if (roles && roles.includes("TUTOR")) {
          return "/students";
        } else if (roles && roles.includes("ADMIN")) {
          return "/tutors";
        }
      }
      return null;
    };

    const redirectPath = getRedirectPath();
    if (
      redirectPath &&
      ["/", "/login", "/otp", `/verifyUser`].includes(location.pathname)
    ) {
      navigate(redirectPath);
    }
  }, [isLoggedIn, location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<OnBoardingScreen />} />
      <Route path="/login" element={<Signup />} />
      <Route path="/otp" element={<Otp />} />
      <Route path="/verifyUser/:id" element={<VerifyUser />} />
      <Route path="*" element={<NotFoundPage />} />
      <Route
        path="/dashboard"
        element={
          <AuthenticatedLayout>
            <Dashboard />
          </AuthenticatedLayout>
        }
      />
      <Route
        path="/performance"
        element={
          <AuthenticatedLayout>
            <Performance />
          </AuthenticatedLayout>
        }
      />
      <Route
        path="/account"
        element={
          <AuthenticatedLayout>
            <AccountSettings />
          </AuthenticatedLayout>
        }
      />
      <Route
        path="/tutors"
        element={
          <AuthenticatedLayout>
            <Tutors />
          </AuthenticatedLayout>
        }
      />
      <Route
        path="/students"
        element={
          <AuthenticatedLayout>
            <Students />
          </AuthenticatedLayout>
        }
      />
      <Route
        path="/speakingtestresults/:id"
        element={
          <AuthenticatedLayout>
            <SpeakingTestResultsMain />
          </AuthenticatedLayout>
        }
      />
      <Route
        path="/writingtestresults/:id"
        element={
          <AuthenticatedLayout>
            <WritingTestResultsMain />
          </AuthenticatedLayout>
        }
      />
      <Route
        path="/listeningtestresults/:id"
        element={
          <AuthenticatedLayout>
            <ListeningTestResultsMain />
          </AuthenticatedLayout>
        }
      />
      <Route
        path="/readingtestresults/:id"
        element={
          <AuthenticatedLayout>
            <ReadingTestResultsMain />
          </AuthenticatedLayout>
        }
      />
      <Route
        path="/editortutor"
        element={
          <AuthenticatedLayout>
            <EditorTutor />
          </AuthenticatedLayout>
        }
      />
    </Routes>
  );
};

export default Routing;
