import { useState } from "react";
import InstituteLogo from "../assets/icons/Institute Tutor Logo.svg";
import TutorLogo from "../assets/icons/Tutor Logo.svg";
import { setEmail, setEmailError, setIsOrg } from "../app/Slices/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import InstituteLogoHover from "../assets/icons/Institute Tutor LogoHover.svg";
import TutorLogoHover from "../assets/icons/Tutor LogoHover.svg";
import  BottomGradient from "../assets/background/top-gradient.svg";
import TopGradient from "../assets/background/bottom-gradient.svg";

const OnBoardingScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hoverInstitute, setHoverInstitute] = useState(false);
  const [hoverTutor, setHoverTutor] = useState(false);
  return (
    <div className="bg-[#FFFFFF] w-full h-screen flex flex-col justify-center items-start relative">
      <img className="absolute top-0 flex w-full justify-center" src={TopGradient} alt="" />
      <img className="absolute bottom-0" src={BottomGradient} alt="" />
    <div className="text-[#A033FF] mt-12 font-manrope font-semibold text-4xl leading-14 tracking-wide ml-20">
      IELTS Tutor
    </div>
    <div className="flex flex-col items-center justify-center h-full w-full z-50">
      <div className="flex justify-center">
        <p className="text-[#4E5664] font-medium text-3xl">
          I would like to register as a
        </p>
      </div>
      <div className="flex justify-center flex-row gap-8 mt-8">
        <button
          onClick={() => {
            dispatch(setIsOrg(true));
            navigate("/login");
            dispatch(setEmail(""));
            dispatch(setEmailError(""));
          }}
          className="group flex flex-col items-center p-3 border border-gray-300 rounded-lg w-1/4 gap-4 cursor-pointer hover:border-[#A033FF]"
          onMouseEnter={() => setHoverInstitute(true)}
          onMouseLeave={() => setHoverInstitute(false)}
        >
          <img
            alt="institute"
            className="w-12 h-12"
            src={hoverInstitute ? InstituteLogoHover : InstituteLogo}
          />
          <p className="text-primaryColor font-medium text-2xl group-hover:text-[#A033FF]">
            Institution
          </p>
          <p className="text-center text-[#737E93]">
            As an institution, you have a more than   1 tutors who can teach/help
            students prepare for different exams
          </p>
        </button>
        <button
          onClick={() => {
            dispatch(setIsOrg(false));
            navigate("/login");
            dispatch(setEmail(""));
            dispatch(setEmailError(""));
          }}
          className="group flex flex-col items-center p-3 border border-gray-300 rounded-lg w-1/4 gap-4 hover:border-[#A033FF] cursor-pointer"
          onMouseEnter={() => setHoverTutor(true)}
          onMouseLeave={() => setHoverTutor(false)}
        >
          <img
            alt="tutor"
            className="w-12 h-12"
            src={hoverTutor ? TutorLogoHover : TutorLogo}
          />
          <p className="text-primaryColor font-medium text-2xl group-hover:text-[#A033FF]">
            Individual Tutor
          </p>
          <p className="text-center text-[#737E93]">
            As an individual tutor, you will teach/help students prepare for
            different exams
          </p>
        </button>
      </div>
    </div>
  </div>
  
  );
};
export default OnBoardingScreen;
