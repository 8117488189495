import { PayloadAction, createSlice } from '@reduxjs/toolkit';


interface LoaderType {
   isLoading: boolean
}

  const initialState:LoaderType = {
    isLoading: false
  };
  const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        setLoader: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        }

    },
  });

  export const { setLoader } = loaderSlice.actions;

export default loaderSlice.reducer;