import React, { useState, useEffect } from "react";
import CustomButton from "../../Common/CustomButton";
import CustomTextField from "../../Common/CustomTextField";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../app/Slices/modalSlice";
import {
  useCreateStudentMutation,
  useAllGroupingStudentsQuery,
  useValidateUserMutation,
} from "../../../api/institutionTutorApi";
import { toast } from "react-toastify";
import { setStudentsBulkClicked } from "../../../app/Slices/studentSlice";
import {
  setEmailError,
  setMobileNumberError,
} from "../../../app/Slices/authSlice";
import { IoTrashBin } from "react-icons/io5";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useAppSelector } from "../../../app/hooks";

const successToastStyle = {
  color: "#292D35",
  fontSize: "12px",
};

const AddNewStudentsInBulk = () => {
  const emailPattern = /^[a-zA-Z0-9][^\s@]*@[^\s@]+\.[a-zA-Z]+$/;
  const [error, setError] = useState("");
  const [emailsError, setEmailsError] = useState("");
  const [addMoreStudentsError, setAddMoreStudentsError] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [validateUser] = useValidateUserMutation();
  const [studentData, setStudentData] = useState<{
    students: { name: string; email: string; mobileNumber: string }[];
  }>({
    students: [
      {
        name: "",
        email: "",
        mobileNumber: "",
      },
    ],
  });

  const dispatch = useDispatch();
  const [createStudent] = useCreateStudentMutation();
  const { refetch } = useAllGroupingStudentsQuery({});

  const { emailError, mobileNumberError } = useAppSelector(
    (state) => state.root.auth
  );

  const onChangeHandler = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAddMoreStudentsError("");
    const { name, value } = e.target;

    setStudentData((prevState) => ({
      ...prevState,
      students: prevState.students.map((student, i) =>
        i === index ? { ...student, [name]: value } : student
      ),
    }));
  };

  const validateDuplicates = () => {
    const emails = studentData.students.map((student) => student.email);
    const mobileNumbers = studentData.students.map(
      (student) => student.mobileNumber
    );

    const duplicateEmail = emails.some(
      (email, index) => emails.indexOf(email) !== index
    );
    const duplicateMobileNumber = mobileNumbers.some(
      (number, index) => mobileNumbers.indexOf(number) !== index
    );

    if (duplicateEmail) {
      setEmailsError("Email already exists");
      setButtonDisabled(true);
    } else {
      setEmailsError("");
    }

    if (duplicateMobileNumber) {
      setError("Mobile number already exists");
      setButtonDisabled(true);
    } else {
      setError("");
    }

    setButtonDisabled(duplicateEmail || duplicateMobileNumber);
  };

  useEffect(() => {
    validateDuplicates();
  }, [studentData]);

  const onClickHandler = async () => {
    try {
      setAddMoreStudentsError("");
      let valid = true;
      for (const student of studentData.students) {
        if (student.email && !emailPattern.test(student.email)) {
          dispatch(setEmailError("Please Enter Valid Email Ex:abc@domain.com"));
          valid = false;
        } else if (
          student.mobileNumber &&
          !isValidPhoneNumber(student.mobileNumber)
        ) {
          dispatch(setMobileNumberError("Please Enter Valid Mobile Number"));
          valid = false;
        }
      }
      if (!valid) {
        return;
      }
      dispatch(setEmailError(""));
      dispatch(setMobileNumberError(""));
      const res = await createStudent(studentData.students);
      refetch();
      dispatch(setStudentsBulkClicked(false));

      if ("error" in res && res.error && "data" in res.error) {
        toast.error(
          <div style={successToastStyle}>Unable to add student</div>,
          { autoClose: 5000 }
        );
      } else {
        dispatch(closeModal());
        toast.success(
          <div style={successToastStyle}>Student(s) Added Successfully</div>,
          { autoClose: 5000 }
        );
      }
    } catch (error) {
      console.log(error, "error12");
    }
  };

  const addMoreStudents = async () => {
    if (studentData.students.length === 0) {
      setStudentData((prevState) => ({
        students: [
          ...prevState.students,
          {
            name: "",
            email: "",
            mobileNumber: "",
          },
        ],
      }));
      setAddMoreStudentsError("");
      return;
    }

    const lastStudent = studentData.students[studentData.students.length - 1];

    if (
      !lastStudent.name ||
      !lastStudent.email ||
      !lastStudent.mobileNumber ||
      !emailPattern.test(lastStudent.email) ||
      !isValidPhoneNumber(lastStudent.mobileNumber)
    ) {
      setAddMoreStudentsError(
        "Please enter valid details for the last student"
      );
      return;
    }

    const validateUserResponse = await validateUser({
      emailId: lastStudent.email,
      mobileNumber: "+" + lastStudent.mobileNumber,
    }).unwrap();

    if (validateUserResponse?.userexists) {
      setAddMoreStudentsError("User already exists");
      toast.error(<div>{validateUserResponse?.message}</div>);
      return;
    }

    setAddMoreStudentsError("");
    setStudentData((prevState) => ({
      students: [
        ...prevState.students,
        {
          name: "",
          email: "",
          mobileNumber: "",
        },
      ],
    }));
  };

  const handleDeleteStudent = (index: number) => {
    const updatedStudents = [...studentData.students];
    updatedStudents.splice(index, 1);
    setStudentData({
      ...studentData,
      students: updatedStudents,
    });
  };

  const handleChangeMobileNumber = (index: number, value: string) => {
    setStudentData((prevState) => ({
      ...prevState,
      students: prevState.students.map((student, i) =>
        i === index ? { ...student, mobileNumber: "+" + value } : student
      ),
    }));
  };

  return (
    <>
      <div className="px-6 w-full h-full mt-4">
        {studentData.students.map((student, index) => (
          <div key={student?.mobileNumber}>
            <div className="flex justify-between items-center">
              <h1 className="mt-3">Student {index + 1}</h1>{" "}
              <button
                title="Delete"
                onClick={() => {
                  handleDeleteStudent(index);
                  setAddMoreStudentsError("");
                  setError("");
                  setEmailsError("");
                }}
              >
                <IoTrashBin className="text-[red] text-xl" />
              </button>
            </div>{" "}
            <div className="flex justify-between">
              <CustomTextField
                placeholder="Name"
                type="text"
                label="Name"
                onChangeHandler={(e) => onChangeHandler(index, e)}
                value={student.name}
                name="name"
                mandatory={true}
              />
              <div className="mt-[13px] mb-[40px]">
                <div className="text-sm mb-1 text-primaryColor">
                  {" "}
                  Mobile Number
                  <span className="text-red-600 text-base font-normal">*</span>
                </div>
                <div className="w-full">
                  <PhoneInput
                    containerClass="w-full"
                    inputClass="border border-gray-300 rounded-2 p-2 focus:outline-none focus:border-black w-[381px] h-[41px]"
                    country={"in"}
                    value={student.mobileNumber}
                    onChange={(e) => handleChangeMobileNumber(index, e)}
                  />
                </div>
              </div>
              <CustomTextField
                placeholder="Email"
                type="text"
                label="Email"
                onChangeHandler={(e) => onChangeHandler(index, e)}
                value={student.email}
                name="email"
                mandatory={true}
              />
            </div>
          </div>
        ))}
        <div className="text-red-600 mt-2 flex justify-center">{error}</div>
        <div className="text-red-600 mt-2 flex justify-center">
          {emailsError}
        </div>
        <div className="text-red-600 text-base font-normal mt-6 flex justify-center">
          {emailError}
        </div>
        <div className="text-red-600 text-base font-normal mt-6 flex justify-center">
          {mobileNumberError}
        </div>
        <div className="text-red-600 text-base font-normal flex justify-center">
          {addMoreStudentsError}
        </div>
        <div className="mt-8">
          <button
            onClick={addMoreStudents}
            className="uppercase text-secondaryColor font-extrabold"
          >
            + Add more students
          </button>
        </div>
      </div>

      <div className="border-t border-t-[#E3E5E9] py-4 bg-white">
        <div className="px-6 w-full flex justify-end">
          <CustomButton
            name="ADD STUDENT(S)"
            onClickHandler={onClickHandler}
            disabled={
              buttonDisabled ||
              studentData.students.some((student) => student.name === "") ||
              studentData.students.some((student) => student.email === "") ||
              studentData.students.some(
                (student) => student.mobileNumber === ""
              )
            }
          />
        </div>
      </div>
    </>
  );
};

export default AddNewStudentsInBulk;
