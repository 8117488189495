import { getItem } from "./storage";

const get_profile = () => {
  const host = window.location.host;
  if (host === "business.cambri.ai") return "production";
  if (host === "localhost:3000") return "dev";
  if (host === "https://staging.api.ielts.tutor.cambri.ai") return "staging"
  return "unknown";
};

let be_url = "https://api.ei.prismatica.ai";
let ieltsUrl = ""

const profile = get_profile();
switch (profile) {
  case "production":
    be_url = "https://api.tutor.ielts.cambri.ai";
    ieltsUrl = "https://ielts1.api.cambri.ai"
    break;
  case "dev":
    be_url = "https://staging.api.ielts.tutor.cambri.ai";
    ieltsUrl="https://staging.api.ielts.cambri.ai";
    break;
  default:
    be_url = "https://staging.api.ielts.tutor.cambri.ai";
    ieltsUrl="https://staging.api.ielts.cambri.ai"
    break;
}

export const serviceUrl = be_url;
export const ielts_url = ieltsUrl;

export const genericHeaders = () => {
  if (getItem("token") != null) {
    return {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getItem("token"), // add the cache & get the token here
    };
  }
  return {
    "Content-Type": "application/json",
  };
};
