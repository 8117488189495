import { useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useAppSelector } from "../../../../app/hooks";
import { getItem } from "../../../../utils/storage";
import ReportProgress from "../../../Common/ReportProgress";
import BandDescription from "../../../Common/BandDescription";
import CommonTab from "../../../Common/Tab/CommonTab";

const ListeningTestResults = () => {
  const {
    getListeningTestScorePartOne,
    getListeningTestScorePartTwo,
    getListeningTestScorePartThree,
    getListeningTestScorePartFour,
  } = useAppSelector((state) => state.root.listeningResults);
  const partType = getItem("testType");
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      label: "Part 1",
      testType: "part1",
      content: (
        <div>
          <ReportProgress partDetails={getListeningTestScorePartOne} />
        </div>
      ),
    },
    {
      label: "Part 2",
      testType: "part2",
      content: (
        <div>
          <ReportProgress partDetails={getListeningTestScorePartTwo} />
        </div>
      ),
    },
    {
      label: "Part 3",
      testType: "part3",
      content: (
        <div>
          <ReportProgress partDetails={getListeningTestScorePartThree} />
        </div>
      ),
    },
    {
      label: "Part 4",
      testType: "part4",
      content: (
        <div>
          <ReportProgress partDetails={getListeningTestScorePartFour} />
        </div>
      ),
    },
  ];

  console.log(activeTab, partType, "activeTab");

  return (
    <div className="w-full grid grid-cols-3 gap-4">
      <div className="border-2 rounded-[8px] bg-white">
        <header>
          <CommonTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabs={tabs}
            partType={partType}
          />
        </header>
      </div>

      {((partType === "full" && activeTab === 0) || partType === "part1") && (
        <BandDescription
          title="Band Description"
          skillLevel={getListeningTestScorePartOne?.skill_level}
          overallScore={getListeningTestScorePartOne?.overall_score}
          description={getListeningTestScorePartOne?.description}
        />
      )}
      {((partType === "full" && activeTab === 1) || partType === "part2") && (
        <BandDescription
          title="Band Description"
          skillLevel={getListeningTestScorePartTwo?.skill_level}
          overallScore={getListeningTestScorePartTwo?.overall_score}
          description={getListeningTestScorePartTwo?.description}
        />
      )}

      {((partType === "full" && activeTab === 2) || partType === "part3") && (
        <BandDescription
          title="Band Description"
          skillLevel={getListeningTestScorePartThree?.skill_level}
          overallScore={getListeningTestScorePartThree?.overall_score}
          description={getListeningTestScorePartThree?.description}
        />
      )}

      {((partType === "full" && activeTab === 3) || partType === "part4") && (
        <BandDescription
          title="Band Description"
          skillLevel={getListeningTestScorePartFour?.skill_level}
          overallScore={getListeningTestScorePartFour?.overall_score}
          description={getListeningTestScorePartFour?.description}
        />
      )}
    </div>
  );
};

export default ListeningTestResults;
