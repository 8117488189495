import clsx from "clsx";
import { twMerge } from "tailwind-merge";

export const safe_get = (
  map_object: any,
  key: string,
  defaultValue: any,
  separator: string = "|"
) => {
  try {
    let current = map_object;
    const parts = key.split(separator);
    for (const part of parts) {
      if (Object.keys(current).includes(part)) {
        current = current[part];
      } else {
        return defaultValue;
      }
    }
    return current;
  } catch (e) {}

  return defaultValue;
};

export const tutorColumn: any = [
  {
    field: "name",
    name: "Tutors",
    selector: (row: any) => row.name,
    sortable: false,
    style: {
      fontSize: "18px",
      color: "#292D35",
    },
  },
  {
    field: "noOfStudentsAssigned",
    name: "Students",
    selector: (row: any) => row.noOfStudentsAssigned,
    sortable: false,
    style: {
      fontSize: "18px",
      color: "#292D35",
    },
  },
  {
    field: "noOfTestsGraded",
    name: "Graded Tests",
    selector: (row: any) => row.noOfTestsGraded,
    sortable: false,
    style: {
      fontSize: "18px",
      color: "#292D35",
    },
  },
  {
    field: "noOfTestsPending",
    name: "Pending Tests",
    selector: (row: any) => row.noOfTestsPending,
    sortable: false,
    style: {
      fontSize: "18px",
      color: "#292D35",
    },
  },
];

export const dashboardDecoding = (dashboards: any) => {
  return dashboards.map((item: any) => {
    if (item === 4001) {
      return { id: "speaking", value: "Speaking" };
    }
    if (item === 4002) {
      return { id: "writing", value: "Writing" };
    }
    if (item === 4003) {
      return { id: "reading", value: "Reading" };
    }
    if (item === 4004) {
      return { id: "listening", value: "Listening" };
    }
    return { id: "-", value: "" };
  });
};

export const fetchStudents = (
  searchValue: string,
  studentsListTutor: any,
  selectedTutorId: string | number,
  key?: "assigned"
) => {
  if (searchValue) {
    return studentsListTutor?.filter(
      (item: any) =>
        item.name.toLowerCase().includes(searchValue.toLowerCase()) &&
        item.tutorId === selectedTutorId
    );
  } else if (key) {
    return studentsListTutor?.filter(
      (item: any) => item.tutorId === selectedTutorId
    );
  } else {
    return studentsListTutor?.filter(
      (item: any) => item.tutorId !== selectedTutorId
    );
  }
};

export function getClassName(item: any, aiFeedback: any, part: string) {
  const baseClass = "border cursor-pointer px-3 py-1 rounded-md";
  let additionalClass = "";

  const isFeedbackMatch =
    aiFeedback?.questionId === item.qId &&
    aiFeedback?.partType === part.toLowerCase();

  if (item.isCorrect) {
    additionalClass = isFeedbackMatch
      ? "bg-[#59A51A] text-[#FFFFFF]"
      : "border-[#59A51A]";
  } else if (!item.isAttempted) {
    additionalClass = isFeedbackMatch
      ? "text-[#FFFFFF] bg-[#737E93] border-[#737E93]"
      : "border-[#737E93]";
  } else {
    additionalClass = isFeedbackMatch
      ? "bg-[#DB3555] text-[#FFFFFF] border-[#DB3555]"
      : "border-[#DB3555]";
  }
  return twMerge(clsx(baseClass, additionalClass));
}

export const capitalizeFirstLetter = (name: string) => {
  if (!name || name.length === 0) {
    return "";
  }
  if (!/^[A-Za-z]/.test(name)) {
    return name;
  }
  return name.charAt(0).toUpperCase() + name.slice(1);
};
