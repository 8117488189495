/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import microphone from "../../../assets/icons/microphone.png";
import writing from "../../../assets/icons/writing.png";
import book from "../../../assets/icons/notebook.png";
import { useDispatch } from "react-redux";
import { setTestId } from "../../../app/Slices/tutorSlice";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import {
  setGetQuestionAnalysisPartOne,
  setGetQuestionAnalysisPartThree,
  setGetQuestionAnalysisPartTwo,
  setSpeakingFullTestDetailedReport,
  setTestType,
} from "../../../app/Slices/speakingResultsSlice";
import axios from "axios";
import {
  fetchWritingFullTestResults,
  getWritingFulltestQuestionAnalysis,
} from "../../../apiUtils";
import { setItem } from "../../../utils/storage";
import Table from "../../Common/CustomTable";
import { ielts_url } from "../../../utils/apiConfig";
import { setSelectedStudentDetails } from "../../../app/Slices/studentSlice";

const GradedTests = () => {
  const dispatch = useDispatch();
  let userId: any;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { gradedTests } = useAppSelector((state) => state.root.tutor);
  const navigate = useNavigate();
  const { writingResultData } = useAppSelector(
    (state) => state.root.writingResults
  );
  const { selectedStudentNumber } = useAppSelector(
    (state) => state.root.student
  );

  const testType = (row: any) => {
    return (
      <div className="flex items-center">
        <img src={book} alt="book_icon" />
        <span className="ml-2">{row.testType}</span>
      </div>
    );
  };

  const bandScore = (row: any) => {
    return (
      <div style={{ width: 40, height: 40 }}>
        <CircularProgressbar value={row.score} text={`${row.score}`} />
      </div>
    );
  };

  const testModule = (row: any) => {
    return (
      <div>
        {row.testModule === "speaking" ? (
          <img alt="mic_icon" src={microphone} />
        ) : (
          <img alt="pencil_icon" src={writing} />
        )}
      </div>
    );
  };

  const columns: any = [
    {
      field: "testModule",
      name: "Type",
      selector: (row: any) => testModule(row),
      width: "70px",
    },
    {
      field: "testType",
      name: "Test",
      selector: (row: any) => testType(row),
    },
    {
      field: "gradedDate",
      name: "Graded On",
    },
    {
      field: "submittedBy",
      name: "Submitted by",
    },
    {
      field: "score",
      name: "Band Score",
      selector: (row: any) => bandScore(row),
    },
  ];

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const fetchSpeakingFullTestResults = async (
    testId: string,
    userId: string
  ) => {
    try {
      const res = await axios.get(
        `${ielts_url}/api/get-test-score-tutor?test_id=${testId}&user_id=${userId}`
      );
      if (res.status === 200) {
        dispatch(setSpeakingFullTestDetailedReport(res.data));
      }
    } catch (error) {
      console.log(error, "error fetching speaking full test results");
    }
  };

  const getSpeakingFulltestQuestionAnalysis = async (
    testId: string,
    testType: string,
    userId: string
  ) => {
    const res = await axios.get(
      `${ielts_url}/api/get-question-analysis-tutor?test_id=${testId}&user_id=${userId}&type=${testType}`
    );
    if (res.status === 200) {
      if (testType === "part1") {
        dispatch(setGetQuestionAnalysisPartOne(res.data));
      } else if (testType === "part2") {
        dispatch(setGetQuestionAnalysisPartTwo(res.data));
      } else if (testType === "part3") {
        dispatch(setGetQuestionAnalysisPartThree(res.data));
      }
    }
  };

  const handleRowClicked = (row: any) => {
    const clickedRowId = row.testId;
    setItem("testType", row.testType);
    if (clickedRowId !== null) {
      handleSpeakingAndWritingTests(row, clickedRowId);
      navigateToTestResults(row);
      dispatchSelectedStudentDetails(row);
      toggleSidebarIfNeeded();
    }
  };

  const handleSpeakingAndWritingTests = (row: any, clickedRowId: any) => {
    dispatch(setTestId(clickedRowId));
    dispatch(setTestType(row.testType));
    const user = gradedTests?.find((item: any) => item.testId === clickedRowId);
    setItem("userId", user.userId);
    setItem("mobileNo", user.mobileNumber);
    let resWritingData =
      writingResultData?.groupbyuser?.[
        selectedStudentNumber as keyof typeof writingResultData.groupbyuser
      ];
    if (row.testType === "full") {
      handleFullTest(row, clickedRowId, user, resWritingData);
    }
  };

  const handleFullTest = (
    row: any,
    clickedRowId: any,
    user: any,
    resWritingData: any
  ) => {
    if (row.testModule === "speaking") {
      fetchSpeakingFullTestResults(clickedRowId, user?.userId);
      handleSpeakingFullTestQuestions(clickedRowId, user?.userId);
    } else if (row.testModule === "writing") {
      fetchWritingFullTestResults(clickedRowId, userId, dispatch);
      handleWritingFullTestQuestions(clickedRowId, resWritingData);
    }
  };

  const handleSpeakingFullTestQuestions = (clickedRowId: any, userId: any) => {
    getSpeakingFulltestQuestionAnalysis(clickedRowId, "part1", userId);
    getSpeakingFulltestQuestionAnalysis(clickedRowId, "part2", userId);
    getSpeakingFulltestQuestionAnalysis(clickedRowId, "part3", userId);
  };

  const handleWritingFullTestQuestions = (
    clickedRowId: any,
    resWritingData: any
  ) => {
    resWritingData?.writing?.answers?.forEach((users: any) => {
      if (users?.writingTestId === clickedRowId) {
        userId = users?.userId;
      }
    });
    getWritingFulltestQuestionAnalysis(clickedRowId, "part1", userId, dispatch);
    getWritingFulltestQuestionAnalysis(clickedRowId, "part2", userId, dispatch);
  };

  const navigateToTestResults = (row: any) => {
    if (row.testModule === "speaking") {
      navigate(`/speakingtestresults/${row.testId}`);
    } else if (row.testModule === "writing") {
      navigate(`/writingtestresults/${row.testId}`);
    }
  };

  const dispatchSelectedStudentDetails = (row: any) => {
    const res = row?.find((item: any) => item.id === row.id);
    dispatch(setSelectedStudentDetails(res));
  };

  const toggleSidebarIfNeeded = () => {
    if (toggleSidebar) {
      toggleSidebar();
    }
  };

  const getSelectedRow = (row: any) => {
    handleRowClicked(row);
  };

  return (
    <div>
      {gradedTests && gradedTests.length > 0 ? (
        <>
          {/* <div className="px-4 flex justify-between items-center">
            <CustomSearch width="w-3/4" icon={redSearch} />
            <div className="flex items-center px-6 w-full justify-end">
              <img
                style={{ width: "20px", height: "20px" }}
                className="mr-5 cursor-pointer"
                src={downArrow}
                title="filter"
                alt="arrow_icon"
              />
            </div>
          </div> */}
          <Table
            getSelectedRow={getSelectedRow}
            data={gradedTests}
            columns={columns}
          />
        </>
      ) : (
        "No Data Available"
      )}
    </div>
  );
};

export default GradedTests;
