import AIFeedbackPartHeader from "./AIFeedbackPartHeader";

export type AnalysisItem = {
  answer: {
    answer: string;
    answer_audio: string;
    pronunciation_score: number;
    fluency_score: number;
    grammar_score: number;
    vocabulary_score: number;
  };
};

export type PartAnalysisType = {
  type: any;
  fraction: string;
  percentage: number;
  accordion?: any;
  setAccordion?: (state: any) => void;
  playAudio?: (audio: any) => void;
  audioRef?: React.RefObject<HTMLAudioElement>;
  data?: any;
  renderItem?: any;
  isAccordionOpen?: boolean;
};

const PartAnalysis = ({
  type,
  fraction,
  percentage,
  isAccordionOpen,
  data,
  renderItem,
  playAudio,
  audioRef,
  accordion,
  setAccordion,
}: PartAnalysisType) => {
  return (
    <div className="lg:w-1/3 xl:w-1/3 2xl:w-1/4 md:w-1/4 w-full h-3/4 border border-[#E3E5E9] rounded-[16px] mb-4">
      <AIFeedbackPartHeader
        type={type}
        fraction={fraction}
        percentage={percentage}
        setAccordion={setAccordion}
        accordion={accordion}
      />
      {isAccordionOpen && (
        <div
          className={`flex flex-col pt-[18px] pl-[11px] relative ${
            isAccordionOpen ? "border-t-2" : ""
          }`}
        >
          {data && data.length > 0 ? (
            data.map((item: any, index: number) =>
              renderItem(item, index, playAudio, audioRef)
            )
          ) : (
            <span className="text-center text-[#737E93]">
              No Data Available
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default PartAnalysis;
