import { CustomButtonProps } from "../../Types";
import { FaSpinner } from "react-icons/fa";

const CustomButton: React.FC<CustomButtonProps> = ({
  name,
  onClickHandler,
  bg = "bg-secondaryColor",
  color = "text-[#FFF]",
  width = "",
  font = "font-medium",
  disabled = false,
  isLoading = false,
  onKeyDown,
  type = "button",
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={`inline-flex not-italic ${width} ${font} ${bg} uppercase justify-center rounded-md border border-transparent px-4 py-2 text-base ${color} focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 ${
        disabled || isLoading
          ? "cursor-not-allowed bg-opacity-40 bg-gray-300 text-gray-400 text-opacity-50"
          : ""
      }`}
      onClick={onClickHandler}
      onKeyDown={onKeyDown}
    >
      {isLoading ? (
        <div className="flex items-center justify-center">
          {name} <FaSpinner className="animate-spin h-5 w-5 text-white ml-3" />
        </div>
      ) : (
        name
      )}
    </button>
  );
};

export default CustomButton;
