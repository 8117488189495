import { useNavigate } from "react-router-dom";
import { getItem } from "../../../utils/storage";

const NotFoundPage = () => {
  const activeMenu = getItem("activeMenu");
  const navigate = useNavigate();
  const handleClick = () => {
    if (activeMenu) {
      navigate(activeMenu);
    }
  };

  return (
    <div className="bg-white">
      <div className="w-9/12 m-auto py-16 min-h-screen flex items-center justify-center">
        <div className="bg-white shadow-md overflow-hidden sm:rounded-lg pb-8">
          <div className="border-t border-gray-200 text-center pt-8 px-2">
            <h1 className="text-9xl font-bold text-purple-400">404</h1>
            <h1 className="text-6xl font-medium py-8">oops! Page not found</h1>
            <p className="text-2xl pb-8 px-12 font-medium">
              Oops! The page you are looking for does not exist.
            </p>
            <button
              onClick={handleClick}
              className="bg-gradient-to-r from-purple-400 to-blue-500 hover:from-pink-500 hover:to-orange-500 text-white font-semibold px-6 py-3 rounded-md mr-6"
            >
              Dashboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
