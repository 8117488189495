import React, { useEffect, useRef, useState } from "react";
import Tutor from "../assets/icons/tutorLogin.svg";
import CustomButton from "../components/Common/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/Store";
import editIcon from "../assets/icons/edit.svg";
import { useNavigate } from "react-router-dom";
import { useVerifyOtpMutation } from "../api/signupApi";
import { setItem } from "../utils/storage";
import { jwtDecode } from "jwt-decode";
import { setIsLoggedIn } from "../app/Slices/authSlice";
import { useAppSelector } from "../app/hooks";
import TopGradient from "../assets/background/bottom-gradient.svg";
import BottomGradient from "../assets/background/top-gradient.svg";

let currentIndex = 0;
function Otp() {
  const [showError, setShowError] = useState<boolean>(false);
  const [otp, setOtp] = useState<string[]>(new Array(4).fill(""));
  const { isOrg } = useAppSelector((state) => state.root.auth);
  const isOtpIncomplete = otp.some((digit) => digit === "");
  const [verifyOtp, { isLoading, data, isError }] = useVerifyOtpMutation();
  const { email } = useSelector((state: RootState) => state.root.auth);
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const [activeOTPIndex, setActiveOTPIndex] = useState<number>(0);
  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(0);
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.target.value;
    const isNumeric = /^\d*$/.test(value);
    if (!isNumeric) {
      return;
    }
    const newOtp: string[] = [...otp];
    newOtp[currentIndex] = value.substring(value.length - 1);
    setOtp(newOtp);
    if (!value) {
      setActiveOTPIndex(currentIndex - 1);
    } else {
      setActiveOTPIndex(currentIndex + 1);
    }
    setShowError(false);
  };

  const keyDownHandler = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ): void => {
    currentIndex = index;
    if (event.key === "Backspace") {
      const newOtp: string[] = [...otp];
      if (newOtp[currentIndex] !== "") {
        newOtp[currentIndex] = "";
        setOtp(newOtp);
        setActiveOTPIndex(currentIndex);
      } else {
        newOtp[currentIndex] = "";
        setOtp(newOtp);
        setActiveOTPIndex(currentIndex - 1);
      }
    }
  };

  useEffect(() => {
    // Timer to decrement time every second
    const timer = setInterval(() => {
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(timer);
        } else {
          setMinutes((prevMinutes) => prevMinutes - 1);
          setSeconds(59);
        }
      } else {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [minutes, seconds]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOTPIndex]);

  const handleVerifyOtp = async () => {
    setShowError(true);
    try {
      const body = {
        emailId: email,
        otp: otp.join(""),
        isOrg: isOrg,
      };
      const res = await verifyOtp(body).unwrap();
      if (res.token) {
        setItem("token", res.token);
        dispatch(setIsLoggedIn(true));
        const decoded = jwtDecode(res.token);
        const userId = (decoded as { userId: string }).userId;
        const roles = (decoded as { roles: string[] }).roles;
        setItem("userId", userId);
        setItem("roles", roles);
        if (isOrg && roles.includes("ADMIN")) {
          navigate("/tutors");
        } else if (isOrg && roles.includes("TUTOR")) {
          navigate("/students");
        } else {
          navigate("/students");
        }
      }
    } catch (error: any) {
      errorMessage(error);
    }
  };

  const onKeyDownHandler = (e: any) => {
    if (e.key === "Enter") {
      handleVerifyOtp();
    }
  };

  const errorMessage = (error: any) => {
    if (showError && otp.join("") !== "") {
      if (error) {
        setErrorMsg(error?.data?.error);
        return error?.data?.error;
      } else {
        return "Unknown error";
      }
    }
  };

  return (
    <div className="bg-[#FFFFFF] w-full h-screen flex flex-col justify-center items-start relative">
      <img
        className="absolute top-0 flex w-full justify-center"
        src={TopGradient}
        alt=""
      />
      <img className="absolute bottom-0" src={BottomGradient} alt="" />
      <button
        onClick={() => navigate("/")}
        className="text-[#A033FF] mt-12 font-manrope font-semibold text-4xl leading-14 tracking-wide ml-20 z-[999]"
      >
        IELTS Tutor
      </button>
      <div className="flex flex-col items-center justify-center h-full w-full z-50">
        <div className="flex justify-center mt-8 flex-row gap-8">
          <img className="h-96" src={Tutor} alt="tutor"></img>
          <div className="flex items-center flex-col gap-4">
            <h1 className="text-[#3C424C] text-2xl  font-extrabold tracking-wider text-center mb-4">
              {isOrg
                ? " Register as Institution"
                : "Register as Individual Tutor"}
            </h1>
            <div className="flex flex-row items-center">
              <p className="text-[#3C424C] mr-1 text-sm font-avenir font-medium text-center mb-4">
                Enter OTP shared on email{" "}
                <span className="spanclasses">{email}</span>
              </p>
              <div>
                <button
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  <img
                    className="cursor-pointer mb-4 ml-1"
                    src={editIcon}
                    alt="editIcon"
                  />
                </button>
              </div>
            </div>
            <div className="flex items-center justify-center ">
              {otp.map((_, index) => (
                <React.Fragment key={""}>
                  <input
                    ref={index === activeOTPIndex ? inputRef : null}
                    type="tel"
                    className="w-8 h-8 m-2 rounded-md bg-transparent outline-none text-center font-semibold text-xl border border-[#E3E5E9] mb-6"
                    onChange={(event) => {
                      onChangeHandler(event);
                    }}
                    onKeyDown={(event) => {
                      keyDownHandler(event, index);
                      onKeyDownHandler(event);
                    }}
                    value={otp[index]}
                  />
                </React.Fragment>
              ))}
            </div>
            {isError ? (
              <h4 className="text-secondaryColor ">{errorMsg}</h4>
            ) : (
              data?.message && <p className="text-[green]">{data.message}</p>
            )}
            <CustomButton
              disabled={isOtpIncomplete || data?.message}
              isLoading={isLoading}
              color="text-[#FFFFFF]"
              bg="bg-[#A033FF]"
              width="w-full"
              name="REGISTER"
              font="font-extrabold"
              onClickHandler={handleVerifyOtp}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Otp;
