import { createSlice } from '@reduxjs/toolkit';

interface AuthState {
  isOrg: boolean;
  email:string;
  emailError:string;
  mobileNumberError:string;
  isLoggedIn:boolean;
}

  const initialState:AuthState = {
    isOrg: false,
    email:"",
    emailError:"",
    mobileNumberError:"",
    isLoggedIn:false,
  };
  const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
      setIsOrg: (state,action) => {
        state.isOrg = action.payload;
      },
      setEmail:(state,action) => {
        state.email = action.payload
      },
      setEmailError:(state,action)=>{
        state.emailError = action.payload
      },
      setMobileNumberError:(state,action)=>{
        state.mobileNumberError = action.payload
      },
      setIsLoggedIn:(state,action) => {
        state.isLoggedIn = action.payload
      },
      resetAuthState: () => initialState,
    },
    
    // extraReducers:builder => (
    //   builder.addMatcher(signupApi.endpoints.signup.matchFulfilled,(state,action) => {
    //     state.email = action.payload // date object
    //   })
    // )
  });

  export const { setIsOrg,setEmail,setEmailError,setMobileNumberError,setIsLoggedIn,resetAuthState } = authSlice.actions;

export default authSlice.reducer;