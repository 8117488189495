const RenderQuestionsName = ({ questionName, dataQuestions }: any) => {
  let isqIdInvolved = (segment: any) =>
    segment.startsWith("{qId:") && segment.endsWith("}");
  if (questionName.includes("{")) {
    let splittedArray = questionName.split(/(\{qId:\d+\})/g);
    return splittedArray?.map((seg: any) => {
      if (isqIdInvolved(seg)) {
        const qId = seg.match(/\d+/)[0];
        const item = dataQuestions?.find((ite: any) => ite.qId === qId);
        return item ? (
          <span
            key={qId}
            className={`${
              item.isCorrect
                ? "text-[#59A51A] font-bold"
                : "text-[#DB3555] font-bold"
            }`}
          >
            {item.userAnswer ? (
              <span>{" " + item.userAnswer + " "}</span>
            ) : (
              "________________"
            )}
          </span>
        ) : (
          item
        );
      } else {
        return seg;
      }
    });
  } else {
    return <span className="font-avenir">{questionName}</span>;
  }
};

export default RenderQuestionsName;
