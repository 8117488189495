import Tab from "../components/Common/Tab";
import ProfileCard from "./Profile";

const AccountSettings = () => {
  const tabs = [{ id: "profile", value: "Profile" }];
  return (
    <div className="ml-6">
      <Tab
        classes={"text-grey"}
        activeClass={"text-secondaryColor border-b-[3px] border-secondaryColor"}
        getActiveTab={(activeTabId) => {}}
        tabs={tabs}
      >
        <Tab.Body id="profile">
          <div className="mt-5">
            <ProfileCard />
          </div>
        </Tab.Body>
      </Tab>
    </div>
  );
};

export default AccountSettings;
