const RenderUserAnswer = ({ cell, matchQuestion }: any) => {
  let startIndex = cell?.indexOf("{");
  let endIndex = cell?.indexOf("}");
  let startString = cell?.substring(0, startIndex).trim();
  let endString = cell?.substring(endIndex + 1).trim();

  console.log(matchQuestion, "matchQuestion");

  return (
    <p>
      {matchQuestion ? (
        <span className="font-avenir">
          {startString}{" "}
          <span
            className={`${
              matchQuestion.isCorrect ? "text-[#59A51A]" : "text-[#DB3555]"
            } font-semibold font-avenir`}
          >
            {matchQuestion.userAnswer ? (
              <>
                <span className=" font-bold ">{matchQuestion.qId}.</span>{" "}
                {matchQuestion.userAnswer}
              </>
            ) : (
              <div className="text-red-600">
                <span className=" font-bold ">{matchQuestion.qId}.</span>
                <span>_______</span>
              </div>
            )}{" "}
          </span>
          {endString}
        </span>
      ) : (
        cell
      )}
    </p>
  );
};

export default RenderUserAnswer;
