import { createSlice } from '@reduxjs/toolkit';


interface EditorState {
   content:string
}

  const initialState:EditorState = {
content:"Tutor"
  };
  const editorSlice = createSlice({
    name: 'editor',
    initialState,
    reducers: {
setContent :(state, action) => {
    state.content = action.payload
}

    },
  });

  export const { setContent } = editorSlice.actions;

export default editorSlice.reducer;