import { useEffect, useRef, useState } from "react";
import CustomSearch from "../Common/CustomSearch";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/Store";
import {
  setDashboards,
  setSearchValue,
  setSelectedStudent,
  setSelectedStudentDetails,
  setSelectedStudentMobileNumber,
  setStudentId,
  setTabs,
  setTotalOverAllBandScore,
} from "../../app/Slices/studentSlice";
import Tab from "../Common/Tab";
import {
  useAllGroupingStudentsQuery,
  useLazyAllGroupingStudentsQuery,
} from "../../api/institutionTutorApi";
import {
  setLastPageSpeaking,
  setSpeakingGraphData,
  setSpeakingResponseData,
} from "../../app/Slices/speakingResultsSlice";
import { useAppSelector } from "../../app/hooks";
import { getItem, setItem } from "../../utils/storage";
import StudentsListFilteredResults from "./StudentsListFilteredResults";
import { useSpeakingAiFeedBackResultsMutation } from "../../api/speakingResultsApi";
import { useWritingAiFeedBackResultsMutation } from "../../api/writingResultsApi";
import { useReadingAiFeedBackResultsMutation } from "../../api/readingResultsApi";
import { useListeningAiFeedBackResultsMutation } from "../../api/listeningResultsApi";
import {
  setLastPageWriting,
  setWritingResultData,
} from "../../app/Slices/writingResultsSlice";
import {
  setReadingLastPage,
  setReadingResultsData,
} from "../../app/Slices/readingResultsSlice";
import {
  setListeningLastPage,
  setListeningResultsData,
} from "../../app/Slices/listeningResultsSlice";
import StudentFilters from "../Common/FiltersDropDown/StudentsFilters";
import Loader from "../Common/CustomLoader";
import useFilters from "../CustomHooks/useFilters";
import { dashboardDecoding } from "../../utils/utils";

const StudentsList = () => {
  const userMobileNumbers = useRef([]);
  const roles = getItem("roles");
  const {
    selectedStudentDetails,
    overallBandScore,
    studentsList,
    searchValue,
    dashboards,
  } = useSelector((state: RootState) => state.root.student);
  const { speakingResponseData } = useAppSelector(
    (state) => state.root.speakingResults
  );
  const { isLoading } = useAllGroupingStudentsQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const dispatch = useDispatch();
  const [speakingAiFeedBackResults, { data: speakingdata }] =
    useSpeakingAiFeedBackResultsMutation();
  const [writingAiFeedBackResults, { data: writingdata }] =
    useWritingAiFeedBackResultsMutation();
  const [readingAiFeedBackResults, { data: readingData }] =
    useReadingAiFeedBackResultsMutation();
  const [listeningAiFeedBackResults, { data: listeningData }] =
    useListeningAiFeedBackResultsMutation();

  const [selectedStudentIndex, setSelectedStudentIndex] = useState<
    null | number
  >(null);
  const handleStudentClicked = (index: number, id: string, item: any) => {
    let selectedStudentMobileNumber = item?.mobileNumber;
    let selectedStudentName = item?.name;
    let selectedStudentSubmitDate = item?.createdDate;
    let totalOverAllBandScore = item?.overAllBandScore;
    let studentId = item?._id;
    let dashboards = item?.dashboard;
    dispatch(setDashboards(dashboards));
    dispatch(setStudentId(studentId));
    dispatch(setTotalOverAllBandScore(totalOverAllBandScore));
    setItem("selectedStudentSubmitDate", selectedStudentSubmitDate);
    if (speakingResponseData?.testscore) {
      let graphData =
        speakingResponseData?.testscore[
          selectedStudentMobileNumber as keyof typeof speakingResponseData.testscore
        ];
      dispatch(setSpeakingGraphData(graphData));
    }
    setItem("mobileNo", selectedStudentMobileNumber);
    dispatch(setSelectedStudentMobileNumber(selectedStudentMobileNumber));
    setItem("selectedStudentName", selectedStudentName);
    dispatch(setSelectedStudentDetails(item));
    setSelectedStudentIndex(index);
    dispatch(setSelectedStudent(id));
    let dashboardsTab = dashboardDecoding(dashboards);
    dispatch(setTabs(dashboardsTab));
  };
  const { isOrg } = useAppSelector((state) => state.root.auth);
  const tabs =
    isOrg && roles?.includes("ADMIN")
      ? [
          { id: "all", value: "All" },
          { id: "new", value: "Newly Added" },
          { id: "unassigned", value: "Unassigned" },
        ]
      : [
          { id: "all", value: "All" },
          { id: "new", value: "Newly Added" },
        ];

  useEffect(() => {
    dispatch(setWritingResultData(writingdata?.data));
    dispatch(setLastPageWriting(writingdata?.lastPageNumber));
    dispatch(setSpeakingResponseData(speakingdata?.data));
    dispatch(setLastPageSpeaking(speakingdata?.lastPageNumber));
    dispatch(setReadingResultsData(readingData?.data));
    dispatch(setReadingLastPage(readingData?.lastPageNumber));
    dispatch(setListeningResultsData(listeningData?.data));
    dispatch(setListeningLastPage(listeningData?.lastPageNumber));
  }, [writingdata, speakingdata, readingData, listeningData, dispatch]);

  useEffect(() => {
    if (selectedStudentDetails?.mobileNumber) {
      if (dashboards.includes(4001)) {
        speakingAiFeedBackResults({
          payload: [selectedStudentDetails?.mobileNumber],
          page: 1,
        });
      }
      if (dashboards.includes(4002)) {
        writingAiFeedBackResults({
          payload: [selectedStudentDetails?.mobileNumber],
          page: 1,
        });
      }
      if (dashboards.includes(4003)) {
        readingAiFeedBackResults({
          payload: [selectedStudentDetails?.mobileNumber],
          page: 1,
        });
      }
      if (dashboards.includes(4004)) {
        listeningAiFeedBackResults({
          payload: [selectedStudentDetails?.mobileNumber],
          page: 1,
        });
      }
    }
  }, [selectedStudentDetails?.mobileNumber, dashboards]);
  const [allGroupingStudents] = useLazyAllGroupingStudentsQuery({});

  const {
    clearFilters,
    filters,
    handleAscendingSorting,
    handleDescendingSorting,
    onChangeRadioHandler,
  } = useFilters(allGroupingStudents);

  useEffect(() => {
    setItem("testType", "");
  }, []);

  return (
    <div>
      <div className="flex justify-between items-center pr-4 pl-4 relative">
        <CustomSearch
          value={searchValue}
          handleSearchChange={(e) => {
            dispatch(setSearchValue(e.target.value));
          }}
        />
        <StudentFilters
          handleDescendingSorting={handleDescendingSorting}
          handleAscendingSorting={handleAscendingSorting}
          onChangeRadioHandler={onChangeRadioHandler}
          filters={filters}
          clearFilters={clearFilters}
        />
      </div>
      <div className="pr-4 pl-4 w-full">
        <Tab
          classes="border border-[#E3E5E9] rounded-full px-4 py-1 text-grey text-xs"
          activeClass="border border-secondaryColor bg-[#FFEAEA] px-4 py-1 rounded-full text-[red] text-xs"
          getActiveTab={() => {}}
          tabs={tabs}
        >
          {tabs.map((tab) => (
            <Tab.Body key={tab.id} id={tab.id}>
              {isLoading ? (
                <Loader isLoading={true} />
              ) : (
                <StudentsListFilteredResults
                  tabId={tab.id}
                  currentData={studentsList}
                  selectedStudentIndex={selectedStudentIndex}
                  selectedStudentDetails={selectedStudentDetails}
                  handleStudentClicked={handleStudentClicked}
                  userMobileNumbers={userMobileNumbers}
                  overallBandScore={overallBandScore}
                  searchValue={searchValue}
                />
              )}
            </Tab.Body>
          ))}
        </Tab>
      </div>
    </div>
  );
};

export default StudentsList;
