import { useRef, useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import PartBody from "./PartBody";
import PartAnalysis from "./PartAnalysis";
import Icon from "../../../../assets/icons/examinerPro.svg";
import PlayIcon from "../../../../assets/icons/PlayIcon.svg";

const SpeakingTestAiFeedback = () => {
  const [accordion, setAccordion] = useState({
    part1: false,
    part2: false,
    part3: false,
  });

  const {
    getQuestionAnalysisPartOne,
    getQuestionAnalysisPartTwo,
    getQuestionAnalysisPartThree,
    testType,
  } = useAppSelector((state) => state.root.speakingResults);

  let partOneAvgFraction = `${getQuestionAnalysisPartOne.score}/${getQuestionAnalysisPartOne.max}`;
  let partTwoAvgFraction = `${getQuestionAnalysisPartTwo.score}/${getQuestionAnalysisPartTwo.max}`;
  let partThreeAvgFraction = `${getQuestionAnalysisPartThree.score}/${getQuestionAnalysisPartThree.max}`;
  let partOnePercentage =
    (getQuestionAnalysisPartOne.score / getQuestionAnalysisPartOne.max) * 100;
  let partTwoPercentage =
    (getQuestionAnalysisPartTwo.score / getQuestionAnalysisPartTwo.max) * 100;
  let partThreePercentage =
    (getQuestionAnalysisPartThree.score / getQuestionAnalysisPartThree.max) *
    100;

  const [partTwoAudio, setPartTwoAudio] = useState<HTMLAudioElement | null>(
    null
  );
  const audioRefTwo = useRef<HTMLAudioElement>(null);

  const playpartTwoAudio = (audioUrl: string) => {
    // Stop the currently playing audio, if any
    if (partTwoAudio) {
      partTwoAudio.pause();
    }

    // Set the source and play the audio
    if (audioRefTwo.current) {
      audioRefTwo.current.src = audioUrl;
      audioRefTwo.current.play();
      setPartTwoAudio(audioRefTwo.current);
    }
  };

  return (
    <div className="flex md:flex-row justify-between flex-col w-full">
      {(testType === "part1" || testType === "full") && (
        <PartAnalysis
          setAccordion={setAccordion}
          type="Part1"
          fraction={partOneAvgFraction}
          percentage={partOnePercentage}
          isAccordionOpen={accordion.part1}
          data={getQuestionAnalysisPartOne.data}
          renderItem={(item: any, index: number) => (
            <PartBody item={item} key={index} />
          )}
          accordion={accordion}
        />
      )}
      {(testType === "part2" || testType === "full") && (
        <PartAnalysis
          type="Part2"
          fraction={partTwoAvgFraction}
          percentage={partTwoPercentage}
          isAccordionOpen={accordion.part2}
          data={getQuestionAnalysisPartTwo.data}
          accordion={accordion}
          setAccordion={setAccordion}
          renderItem={(
            item: any,
            index: number,
            playAudio: any,
            audioRef: any
          ) => (
            <>
              <div className="flex flex-row justify-start">
                <img src={Icon} alt="examinerImg" />
                <div className="border border-[#737E93] rounded-[16px] w-[70%] p-[8px] text-[#737E93] mx-[8px]">
                  {item.answer.answer}
                </div>
                <button
                  className="border border-[#737E93] rounded-full bg-[#E31837] flex justify-center items-center w-[20px] h-[20px] my-[20px]"
                  onClick={() => playAudio(item.answer.answer_audio)}
                >
                  <img src={PlayIcon} alt="playImg" />
                  <audio ref={audioRef}>
                    <track
                      kind="captions"
                      src="captions.vtt"
                      srcLang="en"
                      label="English"
                      default
                    />
                  </audio>
                </button>
              </div>
              <div className="flex justify-end pr-[16px] pb-[16px] text-[#737E93] text-[12px]">
                Pronunciation:{" "}
                {Math.round((item.answer.pronunciation_score / 9) * 100)}%
                Fluency: {Math.round((item.answer.fluency_score / 9) * 100)}%
                Grammar: {Math.round((item.answer.grammar_score / 9) * 100)}%
                Vocabulary:{" "}
                {Math.round((item.answer.vocabulary_score / 9) * 100)}%
              </div>
            </>
          )}
          playAudio={playpartTwoAudio}
          audioRef={audioRefTwo}
        />
      )}

      {(testType === "part3" || testType === "full") && (
        <PartAnalysis
          accordion={accordion}
          setAccordion={setAccordion}
          type="Part3"
          fraction={partThreeAvgFraction}
          percentage={partThreePercentage}
          isAccordionOpen={accordion.part3}
          data={getQuestionAnalysisPartThree.data}
          renderItem={(item: any, index: number) => (
            <PartBody item={item} key={index} />
          )}
        />
      )}
    </div>
  );
};

export default SpeakingTestAiFeedback;
