import React, { useState } from "react";
import { CustomDropDownProps } from "../../Types";

const CustomDropDown : React.FC<CustomDropDownProps> = ({
  options,
  onSelect,
  color = "text-gray-500",
  width = "",
  font = "font-semibold",
  defaultValue,
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const handleSelectChange = (event: any) => {
    setSelectedOption(event.target.value);
    onSelect(event.target.value);
  };
  return (
    <div className="m-2 flex justify-center">
    <select className={`border border-gray-500 rounded-lg ${width} ${font} ${color}`} value={selectedOption} onChange={handleSelectChange}>
      <option className="p-2" value={defaultValue}>
        {defaultValue}
      </option>
      {options?.map((option: any, index: number) => (
        <option key={option.id} value={option.name}>
          {option.name}
        </option>
      ))}
    </select>
    </div>
  );
};

export default CustomDropDown;
