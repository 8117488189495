import { useDispatch } from "react-redux";
import { setContent } from "../../../../app/Slices/writingResultsSlice";

const ShowMoreContent = ({
  content,
  showless,
}: {
  content: any;
  showless: any;
}) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setContent(!showless));
  };

  return (
    <div>
      {showless ? (
        <div>
          {content}
          <button
            className="text-[#E31837] font-semibold cursor-pointer"
            onClick={handleClick}
          >
            show less
          </button>
        </div>
      ) : (
        <div>
          {content?.slice(0, 370)}
          <button
            className="text-[#E31837] font-semibold cursor-pointer"
            onClick={handleClick}
          >
            {content?.length > 200 ? "...see more" : ""}
          </button>
        </div>
      )}
    </div>
  );
};

export default ShowMoreContent;
