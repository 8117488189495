import api from "./api";

export const institutionTutorApi = api.injectEndpoints({
  endpoints: (builder) => ({
    allTutors: builder.query<
      any,
      { sortColumnNumber?: number; sortTypeNumber?: number }
    >({
      query: ({ sortColumnNumber, sortTypeNumber }) => {
        let url = "/api/tutor/getalltutors";
        if (
          sortTypeNumber !== undefined &&
          sortTypeNumber !== 0 &&
          sortColumnNumber !== undefined &&
          sortColumnNumber !== 0
        ) {
          url += `?sortColumnNumber=${sortColumnNumber}&sortTypeNumber=${sortTypeNumber}`;
        }
        return {
          url,
          method: "GET",
        };
      },
      providesTags: ["GetAlltutors"],
    }),
    allGroupingStudents: builder.query<
      any,
      { sortColumnNumber?: number; sortTypeNumber?: number }
    >({
      query: ({ sortColumnNumber, sortTypeNumber }) => {
        let url = "/api/student/fetchgroupingstudents";
        if (
          sortTypeNumber &&
          sortColumnNumber
        ) {
          url += `?sortColumnNumber=${sortColumnNumber}&sortTypeNumber=${sortTypeNumber}`;
        }
        return {
          url,
          method: "GET",
        };
      },
      providesTags:["AllGroupingStudents"]
    }),

    assignedAndUnassignedStudents: builder.query({
      query: (isAssigned) => ({
        url: `/api/student/assignedandunassignedstudents?isAssigned=${isAssigned}`,
        method: "GET",
      }),
    }),
    fetchGradedTests: builder.query({
      query: (id) => ({
        url: `/api/tutor/fetchgradedtests?tutorId=${id}`,
        method: "GET",
      }),
    }),
    getStudentsByTutorId: builder.query({
      query: (id: any) => ({
        url: `/api/student/assignedstudentstutor?tutorId=${id}`,
        method: "GET",
      }),
    }),
    get10DayActivityByTutorId: builder.query({
      query: (id: any) => ({
        url: `/api/tutor/fetchtutortendaysactivity?tutorId=${id}`,
        method: "GET",
      }),
      providesTags:["10DayActivityGraph"]
    }),
    createStudent: builder.mutation({
      query: (payload) => ({
        url: "/api/student/createstudent",
        method: "POST",
        body: payload,
      }),
    }),
    archiveTutor: builder.mutation({
      query: (id) => ({
        url: `/api/tutor/archievetutor?tutorId=${id}`,
        method: "PUT",
      }),
    }),
    updateTutor: builder.mutation({
      query: ({ id, payload }: { id: string; payload: any }) => ({
        url: `/api/tutor/updatetutor?tutorId=${id}`,
        method: "PUT",
        body: payload,
      }),
    }),
    createTutor: builder.mutation({
      query: (payload) => ({
        url: "/api/tutor/createtutor",
        method: "POST",
        body: payload,
      }),
    }),
    assignStudents: builder.mutation({
      query: ({ id, payload }: { id: any; payload: any }) => ({
        url: `api/student/bulkassign?newTutorId=${id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["GetAlltutors","AllGroupingStudents","10DayActivityGraph"],
    }),
    validateUser: builder.mutation({
      query: (payload) => ({
        url: `api/tutoradmin/validateusercontactdetails`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useAllTutorsQuery,
  useLazyAllTutorsQuery,
  useAllGroupingStudentsQuery,
  useLazyAllGroupingStudentsQuery,
  useLazyAssignedAndUnassignedStudentsQuery,
  useAssignedAndUnassignedStudentsQuery,
  useLazyGetStudentsByTutorIdQuery,
  useGetStudentsByTutorIdQuery,
  useCreateTutorMutation,
  useCreateStudentMutation,
  useAssignStudentsMutation,
  useArchiveTutorMutation,
  useUpdateTutorMutation,
  useValidateUserMutation,
  useGet10DayActivityByTutorIdQuery,
  useLazyGet10DayActivityByTutorIdQuery,
  useFetchGradedTestsQuery,
  useLazyFetchGradedTestsQuery
} = institutionTutorApi;
