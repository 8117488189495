import {
  openModal,
  setMobileNumber,
  setName,
  setEmail,
  resetModalState,
} from "../../app/Slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import LeftArrow from "../../assets/icons/leftArrow.png";
import { useNavigate } from "react-router-dom";
import { clearCahce, getItem } from "../../utils/storage";
import { RootState } from "../../app/Store";
import {
  resetStudentState,
  setDashboards,
  setLoaderStudent,
  setStudentsBulkClicked,
} from "../../app/Slices/studentSlice";
import { useAppSelector } from "../../app/hooks";
import { useAllGroupingStudentsQuery } from "../../api/institutionTutorApi";
import {
  setEmailError,
  setMobileNumberError,
  setEmail as setEmailAuth,
  setIsLoggedIn,
  resetAuthState,
} from "../../app/Slices/authSlice";
import { resetTutorState } from "../../app/Slices/tutorSlice";
import { resetWritingResults } from "../../app/Slices/writingResultsSlice";
import { resetSpeakingResults } from "../../app/Slices/speakingResultsSlice";
import { useEffect } from "react";
import { resetProfileData } from "../../app/Slices/profileSlice";

const Header = () => {
  const dispatch = useDispatch();
  const { currentData, isSuccess, isLoading } = useAllGroupingStudentsQuery({});
  const { studentsBulkClicked } = useSelector(
    (state: RootState) => state.root.student
  );
  const selectedStudentName = getItem("selectedStudentName");

  useEffect(() => {
    if (currentData?.allstudents) {
      const selectedStudent = currentData.allstudents.find(
        (item: any) => item.name === selectedStudentName
      );
      if (selectedStudent) {
        const dashboards = selectedStudent.dashboard;
        dispatch(setDashboards(dashboards));
      }
    }
  }, [isSuccess, selectedStudentName]);
  useEffect(() => {
    if (isLoading) {
      dispatch(setLoaderStudent(true));
    } else {
      dispatch(setLoaderStudent(false));
    }
  }, [isLoading]);

  const { tutorsList } = useAppSelector((state) => state.root.tutor);

  const openAddNewTutorDialog = () => {
    dispatch(openModal("addNewTutor"));
  };

  const openAddNewStudentDialog = () => {
    dispatch(openModal("addNewStudent"));
  };

  const path = window.location.pathname;
  const navigate = useNavigate();

  const setHeaderTitle = () => {
    if (path === "/students" && !studentsBulkClicked) {
      return `Students (${currentData?.allstudents?.length ?? 0})`;
    } else if (path === "/students" && studentsBulkClicked) {
      return "Bulk Student Addition";
    } else if (path === "/tutors") {
      return `Tutors (${tutorsList?.length ?? 0})`;
    } else return "";
  };

  const handleLogout = () => {
    dispatch(setEmailAuth(""));
    dispatch(setIsLoggedIn(false));
    clearCahce();
    navigate("/");
    dispatch(setEmail(""));
    dispatch(resetStudentState());
    dispatch(resetTutorState());
    dispatch(resetModalState());
    dispatch(resetWritingResults());
    dispatch(resetSpeakingResults());
    dispatch(resetAuthState());
    dispatch(resetProfileData());
  };

  const backToPath = getItem("activeMenu");
  const testType = getItem("testType");

  const getTestTypeText = () => {
    let testTypeText = "";

    if (testType === "full") {
      testTypeText = "Full Test Result";
    } else if (testType === "part1") {
      testTypeText = "Part 1 Test Result";
    } else if (testType === "part2") {
      testTypeText = "Part 2 Test Result";
    } else if (testType === "part3") {
      testTypeText = "Part 3 Test Result";
    } else if (testType === "part4") {
      testTypeText = "Part 4 Test Result";
    } else return "-";

    return testTypeText;
  };

  const renderHeaderContent = () => {
    if (
      path.includes("writingtestresults") ||
      path.includes("speakingtestresults") ||
      path.includes("readingtestresults") ||
      path.includes("listeningtestresults")
    ) {
      return (
        <div className="flex items-center px-6">
          <button
            onClick={() => {
              if (backToPath) {
                navigate(backToPath);
              } else {
                navigate("/tutors");
              }
            }}
          >
            <img className="mr-4 cursor-pointer" src={LeftArrow} alt="arrow" />
          </button>
          {getTestTypeText()}
        </div>
      );
    }
    if (studentsBulkClicked) {
      return (
        <div className="flex items-center">
          <button
            className="mr-2"
            onClick={() => dispatch(setStudentsBulkClicked(false))}
          >
            {/* SVG content truncated for brevity */}
          </button>{" "}
          {setHeaderTitle()}
        </div>
      );
    }
    return setHeaderTitle();
  };

  return (
    <header className="bg-white p-4 border-b border-b[#E3E5E9] z-[999]">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-2xl text-primaryColor">
            {renderHeaderContent()}
          </h2>
        </div>
        <div className="flex items-center">
          <button
            onClick={handleLogout}
            className="text-xl text-[#3C424C] mr-4"
          >
            Logout
          </button>
          {(path === "/tutors" || path === "/students") && (
            <button
              onClick={() => {
                dispatch(setMobileNumber(""));
                dispatch(setName(""));
                dispatch(setEmail(""));
                dispatch(setEmailError(""));
                dispatch(setMobileNumberError(""));
                path === "/tutors"
                  ? openAddNewTutorDialog()
                  : openAddNewStudentDialog();
              }}
              className="text-base cursor-pointer hover:bg-secondaryColor hover:text-white border border-secondaryColor rounded-full py-2 font-extrabold px-4 text-secondaryColor"
            >
              {path === "/tutors" && "ADD NEW TUTOR"}
              {path === "/students" && "ADD NEW STUDENT"}
            </button>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
