import { createSlice } from "@reduxjs/toolkit";

interface TabType {
  activeId: string;
}

const initialState: TabType = {
  activeId: "speaking",
};
const toastSlice = createSlice({
  name: "tab",
  initialState,
  reducers: {
    setActiveId: (state, action) => {
      state.activeId = action.payload;
    },
  },
});

export const { setActiveId } = toastSlice.actions;

export default toastSlice.reducer;
