import api from "./api";

export const writingResultsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    writingAiFeedBackResults: builder.mutation({
      query: ({ payload, page }) => ({
        url: `/api/writing/writingstudentsdata?page=${page}&limit=${10}`,
        method: "POST",
        body: payload,
      }),
    }),
    fetchWritingTestChart: builder.query({
      query: (id) => ({
        url: `/api/writing/writingtestchart?studentId=${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useWritingAiFeedBackResultsMutation,useFetchWritingTestChartQuery } = writingResultsApi;
