import { FetchBaseQueryArgs } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { toast } from "react-toastify";
import { clearCahce } from "../utils/storage";

let has401ErrorDisplayed = false;
const blackListLoader: string[] = [];
const blackListToast: string[] = [
  "signup",
  "verifyOtp",
  "verifyUser",
  "validateUser",
];
/**
 * It is used to customize the customBase query
 * @param props It will receive props form base quey by default
 * * @returns function of badquery
 */
const customBaseQuery = (props: FetchBaseQueryArgs) => {
  const baseQuery = fetchBaseQuery({ ...props });
  /**
   *
   * @param args
   * @param api
   * @param extraOptions
   * @returns
   */
  const fn: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    if (blackListLoader && !blackListLoader.includes(api.endpoint)) {
      api.dispatch({ type: "loader/setLoader", payload: true });
    }
    const result = (await baseQuery(args, api, extraOptions)) as any;
    try {
      if (!has401ErrorDisplayed && result?.error?.status === 401) {
        toast.error("Your session has expired");
        clearCahce();
        api.dispatch({ type: "auth/setIsLoggedIn", payload: false });
        has401ErrorDisplayed = true;
      }
      setTimeout(() => {
        has401ErrorDisplayed = false;
      }, 4000);
    } catch (error: any) {
      console.log(error, "error");
    } finally {
      api.dispatch({ type: "loader/setLoader", payload: false });
    }
    return result;
  };
  return fn;
};

export default customBaseQuery;
