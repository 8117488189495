import { createSlice } from '@reduxjs/toolkit';

interface ModalState {
  isOpen: boolean;
  modalType: string | null;
  sidebarType:string | null;
  email:string ;
  name: string ;
  mobileNumber : string 
}

const initialState: ModalState = {
  isOpen: false,
  modalType: null,
  sidebarType:null,

  email:"",
  name:"",
  mobileNumber:""

};


const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state,action) => {
      state.isOpen = true;
      state.modalType = action.payload;
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.modalType = null;
    },
    openCustomSidebar:(state,action) => {
      state.isOpen = true;
      state.sidebarType = action.payload
    },
    closeCustomSidebar: (state) => {
      state.isOpen = false;
      state.sidebarType = null;
    },
    setMobileNumber :(state,action)=>{
      state.mobileNumber = action.payload
    },
    setName :(state,action)=>{
      state.name = action.payload
    },
    setEmail : (state,action) =>{
      state.email = action.payload
    },
    resetModalState: () => initialState,
  },
});

export const { openModal, closeModal ,setMobileNumber,setName,setEmail,resetModalState} = modalSlice.actions;

export default modalSlice.reducer;
