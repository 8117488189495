import book from "../../../assets/icons/notebook.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CircularProgressBar from "../../Common/CircularProgressBar";
import { formatDate } from "../../../utils/formatDate";
import { setItem } from "../../../utils/storage";
import { setTestTypeWriting } from "../../../app/Slices/writingResultsSlice";
import { setTestId } from "../../../app/Slices/tutorSlice";

interface ResultCardProps {
  item: any;
  filteredResults?: any;
  navigateTo: string;
}

const AttemptedTestCard: React.FC<ResultCardProps> = ({ item, navigateTo }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getTestTypeDisplay = () => {
    const { testType } = item;
    if (testType) {
      if (testType === "full") {
        return "Full Test";
      } else {
        return testType.charAt(0).toUpperCase() + testType.slice(1);
      }
    } else {
      return testType;
    }
  };

  return (
    <div className="w-full mb-4 mr-1">
      <button
        onClick={() => {
          dispatch(setTestTypeWriting(item.testType));
          dispatch(setTestId(item.testId));
          setItem("testType", item.testType);
          navigate(navigateTo);
          setItem("userId", item.userId);
        }}
        className="flex bg-white flex-row justify-between items-center m-2 hover:bg-[#FFEAEA] border border-[#E3E5E9] rounded-lg p-4 md:w-full w-full"
      >
        <div className=" flex flex-col cursor-pointer justify-start">
          <div className="flex flex-row items-center">
            <div>
              <img src={book} alt="fulltest" />
            </div>
            <div className="pl-2 font-semibold text-18 text-[#3C424C]">
              {getTestTypeDisplay()}
            </div>
          </div>
          <div className="pt-2 text-xs text-grey px-1">
            <p>
              Attempted On{" "}
              {item?.createdAt ? formatDate(item?.createdAt) : "N/A"}
            </p>
            {item.gradedOn === "N/A" ? (
              <p className="italic text-secondaryColor text-xs text-left pt-2">
                Not yet graded
              </p>
            ) : (
              <p className="text-left pt-2">
                Graded On{" "}
                {item?.feedbackAt ? formatDate(item?.feedbackAt) : "N/A"}
              </p>
            )}
          </div>
        </div>
        <div className="w-16">
          <CircularProgressBar score={[item.overAllBand]} />
        </div>
      </button>
    </div>
  );
};
export default AttemptedTestCard;
