import FiltersComponent from "./FiltersComponent";
import { generateRadios } from "./GenerateRadios";

const TutorFilters = ({
  handleAscendingSorting,
  handleDescendingSorting,
  onChangeRadioHandler,
  clearFilters,
  filters,
}: any) => {
  const radios = generateRadios(onChangeRadioHandler, filters, "tutor");
  return (
    <FiltersComponent
      clearFilters={clearFilters}
      handleAscendingSorting={handleAscendingSorting}
      handleDescendingSorting={handleDescendingSorting}
      onChangeRadioHandler={onChangeRadioHandler}
      filters={filters}
      radios={radios}
    />
  );
};

export default TutorFilters;
