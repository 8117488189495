import React, { ErrorInfo, ReactNode } from "react";
import { GrRefresh } from "react-icons/gr";

interface ErrorBoundaryProps {
  children: ReactNode; // Define children prop explicitly
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can also log the error to an error reporting service
    this.logErrorToMyService(error, errorInfo);
  }

  logErrorToMyService(error: Error, errorInfo: ErrorInfo): void {
    // Implement your error reporting logic here
    console.error("Error occurred:", error);
    console.error("Error info:", errorInfo);
    // Example: Send the error and errorInfo to your error reporting service
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="flex justify-center items-center flex-col h-screen">
          <h1 className="text-[40px]">OOPS</h1>
          <h1 className="text-xl">Something went wrong.</h1>
          <GrRefresh className="text-3xl mt-5 cursor-pointer" onClick={() => window.location.reload()} />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
