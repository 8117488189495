import Graph from "../../Graph";
import { useAppSelector } from "../../../../app/hooks";
import { useDispatch } from "react-redux";
import { RootState } from "../../../../app/Store";
import { useEffect } from "react";
import { setActiveTab } from "../../../../app/Slices/studentSlice";
import AttemptedTests from "../../AttemptedTests";
import { setTestTypeWriting } from "../../../../app/Slices/writingResultsSlice";
import {
  setListeningCurrentPage,
  setListeningLastPage,
  setListeningResultsData,
} from "../../../../app/Slices/listeningResultsSlice";
import { useListeningAiFeedBackResultsMutation } from "../../../../api/listeningResultsApi";

const Listening = () => {
  const { listeningGraphData, currentPage, listeningLastPage } = useAppSelector(
    (state: RootState) => state.root.listeningResults
  );
  const [listeningAiFeedBackResults] = useListeningAiFeedBackResultsMutation();
  const dispatch = useDispatch();
  const series = [
    {
      type: "line",
      name: "Tests Graded",
      data: listeningGraphData?.scores,
      color: "#FFECA9",
      marker: {
        fillColor: "#FFF",
        lineColor: "#DB991D",
      },
    },
  ];

  const categories = listeningGraphData?.dates;

  const { listeningResultsData } = useAppSelector(
    (state): any => state.root.listeningResults
  );

  useEffect(() => {
    dispatch(setActiveTab("listening"));
  }, [dispatch]);

  const tabs = [
    { id: "all", value: "All" },
    { id: "full", value: "Full Test" },
    { id: "part1", value: "Part 1" },
    { id: "part2", value: "Part 2" },
    { id: "part3", value: "Part 3" },
    { id: "part4", value: "Part 4" },
  ];

  return (
    <>
      {listeningResultsData ? (
        <>
          <div className="w-full">
            <div className="col-span-2 border border-[#E3E5E9] rounded-lg">
              <Graph
                legendEnabled={false}
                categories={categories}
                title="Listening Test Score"
                series={series}
                tickInterval={3}
                min={0}
                max={9}
              />
            </div>
          </div>
          <AttemptedTests
            currentPage={currentPage}
            lastPage={listeningLastPage}
            data={listeningResultsData}
            tabs={tabs}
            setTestType={setTestTypeWriting}
            apiCall={listeningAiFeedBackResults}
            setData={setListeningResultsData}
            setLastPage={setListeningLastPage}
            setCurrentPage={setListeningCurrentPage}
          />
        </>
      ) : (
        <div className="flex justify-center text-xl text-black text-center">
          No Data Available
        </div>
      )}
    </>
  );
};

export default Listening;
