import { createSlice } from "@reduxjs/toolkit";
import { formatDateToCustomFormat } from "../../utils/reusableFunctions";
import { writingResultsApi } from "../../api/writingResultsApi";

interface WritingResultsState {
  accordian: {
    whatWentWrong: boolean;
    improvements: boolean;
    idealAnswer: boolean;
  };
  activeTabAiFeedback: number;
  showless: boolean;
  testId: string;
  userMobileNumber: [];
  testType: string;
  writingResultData: any;
  writingLoader: boolean;
  writingGraphData: any;
  writingFullTestPart1Data: any;
  writingFullTestPart2Data: any;
  writingFullTestScoreData: any;
  writingError: boolean;
  writingResponseData: any;
  currentPage: any;
  lastPageWriting: any;
}

const initialState: WritingResultsState = {
  writingError: false,
  accordian: {
    whatWentWrong: false,
    improvements: false,
    idealAnswer: false,
  },
  activeTabAiFeedback: 0,
  showless: false,
  testId: "",
  userMobileNumber: [],
  testType: "",
  writingResultData: {},
  writingLoader: false,
  writingGraphData: { scores: [], dates: [] },
  writingFullTestPart1Data: "",
  writingFullTestPart2Data: "",
  writingFullTestScoreData: "",
  writingResponseData: {},
  currentPage: 1,
  lastPageWriting: "",
};
const writingResultsSlice = createSlice({
  name: "writingResults",
  initialState,
  reducers: {
    setControlAccordian(state, action) {
      const { section, bool } = action.payload;
      if (section === "all") {
        state.accordian.whatWentWrong = bool;
        state.accordian.improvements = bool;
        state.accordian.idealAnswer = bool;
      } else {
        (state.accordian as { [key: string]: boolean })[section] = bool;
      }
    },
    setActiveTabAiFeedback: (state, action) => {
      state.activeTabAiFeedback = action.payload;
    },
    setContent: (state, action) => {
      state.showless = action.payload;
    },
    setTestTypeWriting: (state, action) => {
      state.testType = action.payload;
    },
    setWritingResultData: (state, action) => {
      state.writingResultData = action.payload;
    },
    setWritingError: (state, action) => {
      state.writingError = action.payload;
    },
    setWritingCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setLastPageWriting: (state, action) => {
      state.lastPageWriting = action.payload;
    },
    setWritingGraphData: (state, action) => {
      const transformedData = action?.payload?.reduce(
        (result: any, item: any) => {
          result.scores.push(item.score);
          const dateObject = new Date(item.date);
          result.dates.push(formatDateToCustomFormat(dateObject));
          return result;
        },
        { scores: [] as string[], dates: [] as number[] }
      );
      state.writingGraphData = transformedData;
    },
    setWritingFullTestPart1Data: (state, action) => {
      state.writingFullTestPart1Data = action.payload;
    },
    setWritingFullTestPart2Data: (state, action) => {
      state.writingFullTestPart2Data = action.payload;
    },
    setWritingFullTestScoreData: (state, action) => {
      state.writingFullTestScoreData = action.payload;
    },
    setWritingingResponseData: (state, action) => {
      state.writingResponseData = action.payload;
    },
    resetWritingResults: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      writingResultsApi.endpoints.fetchWritingTestChart.matchFulfilled,
      (state, action) => {
        const transformedData = action.payload?.reduce(
          (result: any, item: any) => {
            result.scores.push(item.score);
            const dateObject = new Date(item.date);
            result.dates.push(formatDateToCustomFormat(dateObject));
            return result;
          },
          { scores: [] as string[], dates: [] as number[] }
        );
        state.writingGraphData = transformedData;
      }
    );
  },
});

export const {
  setControlAccordian,
  setActiveTabAiFeedback,
  setContent,
  setTestTypeWriting,
  setWritingResultData,
  setWritingGraphData,
  setWritingFullTestPart1Data,
  setWritingFullTestPart2Data,
  setWritingFullTestScoreData,
  resetWritingResults,
  setWritingError,
  setWritingCurrentPage,
  setLastPageWriting,
  setWritingingResponseData,
} = writingResultsSlice.actions;

export default writingResultsSlice.reducer;
