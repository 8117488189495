import Tutor from "../assets/icons/tutorLogin.svg";
import TopGradient from "../assets/background/bottom-gradient.svg";
import BottomGradient from "../assets/background/top-gradient.svg";
import { useDispatch } from "react-redux";
import CustomTextField from "../components/Common/CustomTextField";
import CustomButton from "../components/Common/CustomButton";
import { useNavigate } from "react-router-dom";
import { setEmail, setEmailError } from "../app/Slices/authSlice";
import { useSignupMutation } from "../api/signupApi";
import { useState } from "react";
import { useAppSelector } from "../app/hooks";

const Signup = () => {
  const { email, emailError, isOrg } = useAppSelector(
    (state) => state.root.auth
  );
  const [signup, { isLoading }] = useSignupMutation();
  const dispatch = useDispatch();
  const emailPattern = /^[a-zA-Z0-9][^\s@]*@[^\s@]+\.[a-zA-Z]+$/;
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleGetOTP = async () => {
    try {
      if (!emailPattern.test(email)) {
        dispatch(setEmailError("Please Enter Valid Email Ex:abc@domail.com"));
      } else {
        dispatch(setEmailError(""));
        const res = await signup({ emailId: email, isOrg: isOrg });
        if ("error" in res && res.error && "data" in res.error) {
          setErrorMessage("User Type Is Invalid");
        } else {
          navigate("/otp");
        }
      }
    } catch (err) {
      console.error(err, "error message...");
    }
  };
  const onKeyDownHandler = (e: any) => {
    if (e.key === "Enter") {
      handleGetOTP();
    }
  };
  return (
    <div className="bg-[#FFFFFF] w-full h-screen flex flex-col justify-center items-start relative">
      <img
        className="absolute top-0 flex w-full justify-center"
        src={TopGradient}
        alt=""
      />
      <img className="absolute bottom-0" src={BottomGradient} alt="" />
      <button
        onClick={() => navigate("/")}
        className="text-[#A033FF] mt-12 font-manrope font-semibold text-4xl leading-14 tracking-wide ml-20 z-[999]"
      >
        IELTS Tutor
      </button>
      <div className="flex flex-col items-center justify-center h-full w-full z-50">
        <div className="flex justify-center mt-8 flex-row gap-8">
          <img className="h-96" src={Tutor} alt="tutor" />
          <div className="flex items-center flex-col gap-7">
            <p className="text-[#3C424C] text-2xl font-extrabold">
              {isOrg
                ? "Register as Institution"
                : "Register as Individual Tutor"}
            </p>
            <div className="w-full">
              <CustomTextField
                value={email}
                type="text"
                placeholder="Email"
                onChangeHandler={(e) => {
                  if (emailPattern.test(email)) {
                    dispatch(setEmailError(""));
                  }
                  dispatch(setEmail(e?.target?.value?.toLowerCase()));
                  if (e.target.value === "") {
                    setErrorMessage("");
                    dispatch(setEmailError(""));
                  }
                }}
                label=""
                mandatory={false}
                onKeyDown={onKeyDownHandler}
              />
            </div>
            <CustomButton
              disabled={!email || isLoading}
              isLoading={isLoading}
              color="text-[#FFFFFF]"
              bg="bg-[#A033FF]"
              name="GET OTP"
              font="font-extrabold"
              width="w-full"
              onClickHandler={handleGetOTP} // Call handleGetOTP function
            />
            <p className="text-red-600 text-base font-normal mt-6">
              {errorMessage}
            </p>
            <p className="text-red-600 text-base font-normal mt-6">
              {emailError}
            </p>
            {/* <p className="text-grey text-base font-normal mt-6">
              Or continue with
            </p>
            <div className="flex flex-row gap-1">
              <img alt="facebook" src={Facebook}></img>
              <img alt="apple" src={Apple}></img>
              <img alt="google" src={Google}></img>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Signup;
