import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { institutionTutorApi } from "../../api/institutionTutorApi";
import { Student } from "../../components/Types";
type StudentInitialState = {
  tab: [];
  loaderStudent: boolean;
  dashboards: number[];
  value: number;
  studentsList: Student;
  studentsListTutor: Student;
  selectedStudentId: string;
  selectedStudentDetails: Student;
  studentsBulkClicked: boolean;
  selectedStudentNumber: string;
  totalTestsAttempted: number;
  overallBandScore: number;
  writingLoader: boolean;
  speakingLoader: boolean;
  totalOverAllBandScore: any;
  studentId: string;
  tutorId: string;
  sortColumnNumber: number;
  sortTypeNumber: number;
  searchValue: any;
  searchValueForTutor: any;
  reassignClick: boolean;
  selectedTutotId: string;
  testsCompletedCount: number;
  totalOverAllBandScoreTutor: any;
  activeTab?: string;
};

const initialState: StudentInitialState = {
  tab: [],
  loaderStudent: false,
  dashboards: [],
  value: 0,
  studentsBulkClicked: false,
  reassignClick: false,
  searchValue: "",
  searchValueForTutor: "",
  selectedTutotId: "",
  studentsList: {
    name: "",
    students: 0,
    testsTaken: "",
    time: "",
    imgSrc: "",
    _id: "",
    isActive: false,
    email: "",
    profilePhoto: "",
    mobileNumber: "",
    isOrg: false,
    orgId: "",
    isArchieved: false,
    createdDate: "",
    updatedDate: "",
    assignedTo: "",
    testsAttempted: "",
    overallBandScore: "",
    tutorId: "",
  },
  studentsListTutor: {
    name: "",
    students: 0,
    testsTaken: "",
    time: "",
    imgSrc: "",
    _id: "",
    isActive: false,
    email: "",
    profilePhoto: "",
    mobileNumber: "",
    isOrg: false,
    orgId: "",
    isArchieved: false,
    createdDate: "",
    updatedDate: "",
    assignedTo: "",
    testsAttempted: "",
    overallBandScore: "",
    tutorId: "",
  },
  selectedStudentId: "",
  selectedStudentNumber: "",
  totalTestsAttempted: 0,
  overallBandScore: 0,
  writingLoader: false,
  speakingLoader: false,
  totalOverAllBandScore: "",
  totalOverAllBandScoreTutor: "",
  testsCompletedCount: 0,
  studentId: "",
  tutorId: "",
  sortColumnNumber: 0,
  sortTypeNumber: 0,
  selectedStudentDetails: {},
  activeTab: "speaking",
};

const studentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    setStudentsList: (state, action: PayloadAction<Student>) => {
      state.studentsList = action.payload;
    },
    setSelectedStudent: (state, action) => {
      state.selectedStudentId = action.payload;
    },
    setSelectedStudentDetails: (state, action: PayloadAction<Student>) => {
      state.selectedStudentDetails = action.payload;
    },
    setStudentsBulkClicked: (state, action) => {
      state.studentsBulkClicked = action.payload;
    },
    setSelectedStudentMobileNumber: (state, action) => {
      state.selectedStudentNumber = action.payload;
    },
    setTotalTestsCompleted: (state, action) => {
      state.testsCompletedCount = action.payload;
    },
    setReassignClick: (state, action) => {
      state.reassignClick = action.payload;
    },
    setTotalOverAllBandScoreTutor: (state, action) => {
      state.totalOverAllBandScoreTutor = action.payload;
    },
    setLoaderStudent: (state, action) => {
      state.loaderStudent = action.payload;
    },
    setTotalOverAllBandScore: (state, action) => {
      state.totalOverAllBandScore = action.payload;
    },
    setStudentId: (state, action) => {
      state.studentId = action.payload;
    },
    setTutorId: (state, action) => {
      state.tutorId = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setSearchValueTutor: (state, action) => {
      state.searchValueForTutor = action.payload;
    },
    setStudentsListTutor: (state, action) => {
      state.studentsListTutor = action.payload;
    },
    setDashboards: (state, action) => {
      state.dashboards = action.payload;
    },
    setTabs: (state, action) => {
      state.tab = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    resetStudentState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      institutionTutorApi.endpoints.allGroupingStudents.matchFulfilled,
      (state, action) => {
        state.studentsList = action.payload;
        state.studentsListTutor = action.payload;
      }
    );
    builder.addMatcher(
      institutionTutorApi.endpoints.allGroupingStudents.matchFulfilled,
      (state, action) => {
        state.studentsList = action.payload;
        state.studentsListTutor = action.payload;
      }
    );
  },
});

export const {
  setStudentsList,
  setSelectedStudent,
  setSelectedStudentDetails,
  setStudentsBulkClicked,
  setSelectedStudentMobileNumber,
  setTotalOverAllBandScore,
  setTotalTestsCompleted,
  setTotalOverAllBandScoreTutor,
  setStudentId,
  setStudentsListTutor,
  setTutorId,
  setSearchValue,
  resetStudentState,
  setReassignClick,
  setSearchValueTutor,
  setActiveTab,
  setDashboards,
  setLoaderStudent,
  setTabs,
} = studentSlice.actions;
export default studentSlice.reducer;
