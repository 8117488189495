import CircularProgressBar from "../CircularProgressBar";

const ReportProgress = ({ partDetails }: any) => {
  return partDetails ? (
    <div>
      <div className="flex flex-row flex-wrap justify-between">
        <div className="w-[22.88rem] h-[18.75rem] flex justify-center items-center mb-2">
          <div className="w-[180px] h-[180px] ">
            <CircularProgressBar score={[partDetails?.overall_score]} />
            <div className="text-[20px] font-Avenir font-medium text-[#3C424C] flex justify-center pt-[20px]">
              Avg Band Score
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div>
      <p className="text-center my-20">No data</p>
    </div>
  );
};

export default ReportProgress;
