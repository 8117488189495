import { CustomSideOverlayProps } from "../../Types";

const CustomSideOverlay: React.FC<CustomSideOverlayProps> = ({
  isSidebarOpen,
  toggleSidebar,
  children,
  title,
  width,
  handleCloseSidebar,
  style,
}) => {
  return (
    <>
      {isSidebarOpen && (
        <div style={style}>
          {isSidebarOpen && (
            <button className="backdrop" onClick={toggleSidebar}></button>
          )}
          <div
            className={`border-l border-l-[#E3E5E9] sidebar ${
              isSidebarOpen
                ? "open 2xl:w-[35%] xl:w-[40%] lg-[45%] md:w-[50%] sm:[50%] w-[80%] overflow-y-auto"
                : "close"
            }`}
          >
            <div className="flex justify-between items-center px-6 py-4 border-b border-b-[#E3E5E9]">
              <p className="text-xl text-primaryColor font-medium">{title}</p>
              <button
                onClick={() => {
                  if (handleCloseSidebar) {
                    handleCloseSidebar();
                  }
                  toggleSidebar();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  className="cursor-pointer"
                >
                  <path
                    d="M15.2803 14.2198C15.35 14.2895 15.4053 14.3722 15.443 14.4632C15.4807 14.5543 15.5001 14.6519 15.5001 14.7504C15.5001 14.849 15.4807 14.9465 15.443 15.0376C15.4053 15.1286 15.35 15.2114 15.2803 15.281C15.2106 15.3507 15.1279 15.406 15.0369 15.4437C14.9458 15.4814 14.8482 15.5008 14.7497 15.5008C14.6511 15.5008 14.5536 15.4814 14.4625 15.4437C14.3715 15.406 14.2887 15.3507 14.2191 15.281L7.99968 9.06073L1.78031 15.281C1.63958 15.4218 1.44871 15.5008 1.24968 15.5008C1.05066 15.5008 0.85979 15.4218 0.71906 15.281C0.578329 15.1403 0.499268 14.9494 0.499268 14.7504C0.499268 14.5514 0.578329 14.3605 0.71906 14.2198L6.93937 8.00042L0.71906 1.78104C0.578329 1.64031 0.499268 1.44944 0.499268 1.25042C0.499268 1.05139 0.578329 0.860523 0.71906 0.719792C0.85979 0.579062 1.05066 0.5 1.24968 0.5C1.44871 0.5 1.63958 0.579062 1.78031 0.719792L7.99968 6.9401L14.2191 0.719792C14.3598 0.579062 14.5507 0.5 14.7497 0.5C14.9487 0.5 15.1396 0.579062 15.2803 0.719792C15.421 0.860523 15.5001 1.05139 15.5001 1.25042C15.5001 1.44944 15.421 1.64031 15.2803 1.78104L9.06 8.00042L15.2803 14.2198Z"
                    fill="#737E93"
                  />
                </svg>
              </button>
            </div>
            <div>{children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomSideOverlay;
