const RenderHtml = ({ aiFeedback }: any) => {
  const replacedTextParts = aiFeedback?.questionName?.split("{}");
  return (
    <span>
      {aiFeedback.qId}
      {replacedTextParts?.map((part: any, index: number) => (
        <>
          {index > 0 && (
            <strong
              className={`font-bold ${
                aiFeedback.isCorrect ? "text-[#59A51A]" : "text-[#DB3555]"
              }`}
            >
              {aiFeedback.userAnswer ? aiFeedback.userAnswer : "_________"}
            </strong>
          )}
          {part}
        </>
      ))}
    </span>
  );
};
export default RenderHtml;
