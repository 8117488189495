import React, { useRef } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getCommonChartOptions } from "../Students/getCommonChartOptions";

Highcharts.setOptions({
  credits: {
    enabled: false,
  },
});

export interface GraphProps extends HighchartsReact.Props {
  title: string;
  tickInterval: number;
  min: number;
  max: number;
  categories: string[];
  legendEnabled: boolean;
}

const Graph: React.FC<GraphProps> = ({
  title,
  series,
  tickInterval,
  min,
  max,
  categories,
  legendEnabled,
  ...props
}) => {
  const options: Highcharts.Options = getCommonChartOptions(
    title,
    legendEnabled,
    categories,
    min,
    max,
    tickInterval,
    series
  );

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
      {...props}
    />
  );
};

export default Graph;
