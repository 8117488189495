import Students from "../../Tutor/StudentsAndGradedTests/Students";
import Tab from "../../Common/Tab";
import GradedTests from "../../Tutor/StudentsAndGradedTests/GradedTests";

const StudentsAndTestGrades = () => {
  return (
    <div className="w-11/12 mx-auto mt-8 border border-[#E3E5E9] px-4 rounded-lg bg-[#fff] mb-4 pb-2">
      <Tab
        classes={"text-[#737E93]"}
        activeClass={"text-[red] border-b-[3px] border-secondaryColor"}
        tabs={[
          { id: "students", value: "Students" },
          { id: "gradedTests", value: "Graded Tests" },
        ]}
      >
        <Tab.Body id={"students"}>
          <div className="mt-5">
            <Students />
          </div>
        </Tab.Body>
        <Tab.Body id="gradedTests">
          <div className="flex justify-center mt-6 mb-8 overflow-x-auto">
            <GradedTests />
          </div>
        </Tab.Body>
      </Tab>
    </div>
  );
};

export default StudentsAndTestGrades;
