import { useDispatch } from "react-redux";
import {
  setActiveTabAiFeedback,
  setContent,
  setControlAccordian,
} from "../../../app/Slices/writingResultsSlice";
import { useState } from "react";

const Tab = ({
  label,
  onClick,
  isActive,
}: {
  label: string;
  onClick: any;
  isActive: boolean;
}) => {
  return (
    <button
      className={`cursor-pointer px-4 pt-4 border-b-[5px] rounded-sm font-extrabold ${
        isActive ? "border-red-600 text-[#E31837] " : "border-transparent"
      }`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export const Tabs = ({ tabs, partType }: { tabs: any; partType: string }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index: number) => {
    dispatch(setActiveTabAiFeedback(index));
    dispatch(setContent(false));
    dispatch(setControlAccordian({ section: "all", bool: false }));
    setActiveTab(index);
  };

  let contentToShow: string = "";
  if (partType === "part1") {
    contentToShow = tabs[0]?.content;
  } else if (partType === "part2") {
    contentToShow = tabs[1]?.content;
  } else if (partType === "part3") {
    contentToShow = tabs[2]?.content;
  } else if (partType === "part4") {
    contentToShow = tabs[3]?.content;
  } else {
    contentToShow = tabs[activeTab]?.content;
  }

  return (
    <div className="rounded-t-[8px]  pl-4">
      <div className="flex mb-4">
        {partType === "part1" && (
          <Tab
            key={tabs[0]}
            label={tabs[0]?.label}
            onClick={() => handleTabClick(0)}
            isActive={true}
          />
        )}
        {partType === "part2" && (
          <Tab
            key={tabs[0]}
            label={tabs[0]?.label}
            onClick={() => handleTabClick(1)}
            isActive={true}
          />
        )}
        {partType === "part3" && (
          <Tab
            key={tabs[0]}
            label={tabs[0]?.label}
            onClick={() => handleTabClick(2)}
            isActive={true}
          />
        )}
        {partType === "part4" && (
          <Tab
            key={tabs[0]}
            label={tabs[0]?.label}
            onClick={() => handleTabClick(3)}
            isActive={true}
          />
        )}
        {partType !== "part1" &&
          partType !== "part2" &&
          partType !== "part3" &&
          partType !== "part4" &&
          tabs.map((tab: any, index: number) => (
            <Tab
              key={tab?.partType}
              label={tab?.label}
              onClick={() => handleTabClick(index)}
              isActive={index === activeTab}
            />
          ))}
      </div>

      <div>{contentToShow}</div>
    </div>
  );
};

export default Tabs;
