export function getCommonChartOptions(
  title: string,
  legendEnabled: boolean,
  categories: string[],
  min: number,
  max: number,
  tickInterval: number,
  series: Highcharts.SeriesOptionsType[]
): Highcharts.Options {
  return {
    accessibility: {
      enabled: false,
    },
    chart: {
      marginTop: 80,
      height: 250,
      style: {
        borderRadius: 10,
      },
    },
    title: {
      text: title,
      align: "left",
      style: {
        color: "#292D35",
        fontSize: "18px",
        fontWeight: "normal",
        marginTop: "10px",
      },
    },
    legend: {
      enabled: legendEnabled,
      layout: "horizontal",
      align: "right",
      verticalAlign: "top",
      x: 0,
      y: -40,
      itemStyle: {
        color: "#737E93",
        fontSize: "12px",
      },
      itemCheckboxStyle: {
        color: "blue",
        width: 2,
      },
    },
    plotOptions: {
      line: {
        marker: {
          symbol: "circle",
          radius: 4,
          fillColor: "#FFF",
          lineWidth: 2,
          lineColor: undefined,
        },
        dataLabels: {
          enabled: false,
          color: "#333",
          borderRadius: 5,
          backgroundColor: "#ffffff",
          padding: 5,
        },
        color: "#0070C0",
        lineWidth: 6,
      },
    },
    xAxis: {
      type: "category",
      categories: categories,
      labels: {
        style: {
          color: "#737E93",
          fontSize: "12px",
        },
      },
      max: categories?.length - 1,
      endOnTick: false,
      gridLineDashStyle: "Dot",
    },
    yAxis: {
      min: min,
      max: max,
      tickInterval: tickInterval,
      title: {
        text: null,
      },
      labels: {
        style: {
          color: "#737E93",
          fontSize: "12px",
        },
      },
    },
    series: series,
  };
}
