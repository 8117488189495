const useAiFeedBack = () => {
  const handleAIFeedback = (
    item: any,
    part: any,
    audio: string,
    setAIFeedback: any,
    data?: any
  ) => {
    let paraTitle = "";
    let paraContent = "";
    if (data) {
      paraTitle = data?.["passage"]?.["title"];
      paraContent = data?.["passage"]?.["content"];
    }

    if (
      item.questionType === "Multiple Choice (more than one answer)" &&
      Array.isArray(item?.exactAnswer)
    ) {
      item = {
        ...item,
        exactAnswer: item?.exactAnswer?.join(", "),
      };
    }
    setAIFeedback((prevState: any) => ({
      ...prevState,
      partType: part,
      paragraphTitle: paraTitle,
      paragraphContent: paraContent,
      questionId: item.qId,
      questionName: item.qName,
      questionType: item.questionType,
      userAnswer: item.userAnswer,
      exactAnswer: item.exactAnswer,
      isCorrect: item.isCorrect,
      isSelected: true,
      isAttempted: item.isAttempted,
      table: item.table,
      audio: audio,
    }));
  };

  const extractQuestionId = (string: any) => {
    // Find the indices of curly braces
    let startIndex = string.indexOf("{");
    let lastIndex = string?.indexOf("}");

    // Check if both curly braces are present
    if (startIndex !== -1 && lastIndex !== -1) {
      // Extract the string between curly braces
      let extractedString = string
        .slice(startIndex + 1, lastIndex)
        .replace(/\s/g, "");

      // Find the index of colon within the extracted string
      let index = extractedString.indexOf(":");

      // Check if colon is present
      if (index !== -1) {
        // Extract the value after the colon
        let result = extractedString.substring(index + 1);
        return result;
      } else {
        console.log("Colon not found within curly braces.");
      }
    } else {
      console.log("Curly braces not found.");
    }
  };
  return {
    extractQuestionId,
    handleAIFeedback,
  };
};

export default useAiFeedBack;
