import Graph from "../../Graph";
import { useAppSelector } from "../../../../app/hooks";
import { useDispatch } from "react-redux";
import { RootState } from "../../../../app/Store";
import { useEffect } from "react";
import { setActiveTab } from "../../../../app/Slices/studentSlice";
import AttemptedTests from "../../AttemptedTests";
import {
  setReadingCurrentPage,
  setReadingLastPage,
  setReadingResultsData,
} from "../../../../app/Slices/readingResultsSlice";
import { useReadingAiFeedBackResultsMutation } from "../../../../api/readingResultsApi";
import { setTestTypeWriting } from "../../../../app/Slices/writingResultsSlice";

const Reading = () => {
  const { readingGraphData, currentPage, readingLastPage } = useAppSelector(
    (state: RootState) => state.root.readingResults
  );
  const [readingAiFeedBackResults] = useReadingAiFeedBackResultsMutation();
  const dispatch = useDispatch();
  const series = [
    {
      type: "line",
      name: "Tests Graded",
      data: readingGraphData?.scores,
      color: "#FFECA9",
      marker: {
        fillColor: "#FFF",
        lineColor: "#DB991D",
      },
    },
  ];

  const categories = readingGraphData?.dates;

  const { readingResultsData } = useAppSelector(
    (state): any => state.root.readingResults
  );

  useEffect(() => {
    dispatch(setActiveTab("reading"));
  }, [dispatch]);

  const tabs = [
    { id: "all", value: "All" },
    { id: "full", value: "Full Test" },
    { id: "part1", value: "Part 1" },
    { id: "part2", value: "Part 2" },
    { id: "part3", value: "Part 3" },
  ];

  return (
    <>
      {readingResultsData ? (
        <>
          <div className="w-full">
            <div className="col-span-2 border border-[#E3E5E9] rounded-lg">
              <Graph
                legendEnabled={false}
                categories={categories}
                title="Reading Test Score"
                series={series}
                tickInterval={3}
                min={0}
                max={9}
              />
            </div>
          </div>
          <AttemptedTests
            currentPage={currentPage}
            lastPage={readingLastPage}
            data={readingResultsData}
            tabs={tabs}
            setTestType={setTestTypeWriting}
            apiCall={readingAiFeedBackResults}
            setData={setReadingResultsData}
            setLastPage={setReadingLastPage}
            setCurrentPage={setReadingCurrentPage}
          />
        </>
      ) : (
        <div className="flex justify-center text-xl text-black text-center">
          No Data Available
        </div>
      )}
    </>
  );
};

export default Reading;
