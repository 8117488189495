import React from "react";
import { useNavigate } from "react-router-dom";
import errorImage from "../assets/icons/error_image.svg";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center h-screen text-gray-800 p-4 bg-white">
      <img src={errorImage} alt="errorImage" className="w-[6rem] h-[6rem]" />
      <h5 className="text-lg text-black">
        You dont have a access for this page ,
      </h5>
      <h4 className="text-lg text-black mb-8">
        Please contact our admin at{" "}
        <span className="text-black font-bold cursor-pointer ml-2 underline">
          <a href="mailto:anupama@cambri.ai">anupama@cambri.ai</a>
        </span>
      </h4>

      <button
        className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 transition duration-300"
        onClick={() => navigate("/students")}
      >
        Go to Homepage
      </button>
    </div>
  );
};

export default ErrorPage;
