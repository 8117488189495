const RenderMatchingHeadingQname = ({
  questionName,
  userAnswer,
  isCorrect,
}: {
  questionName: string;
  userAnswer: string;
  isCorrect: boolean;
}) => {
  const parts = questionName.split(/\{qId: \d+\}./);
  return (
    <div>
      {
        <div
          className={`${
            isCorrect
              ? "text-[#59A51A] border border-[#59A51A]"
              : "text-[#DB3555] border border-[#DB3555]"
          }  rounded-md px-3 py-2 my-2 w-3/4 font-avenir`}
        >
          {userAnswer}
        </div>
      }
      {parts[1]}
    </div>
  );
};

export default RenderMatchingHeadingQname;
