import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ResultsSkeleton = () => {
  return (
    <div className="flex flex-col gap-6 mt-4">
       <div className="flex justify-between">
            <div>
        <Skeleton width={100} />
      </div>
      <div>
        <Skeleton width={100}/>
      </div>
      <div>
        <Skeleton width={100}/>
      </div>
      <div>
        <Skeleton width={100}/>
      </div>
      </div>
    <div className="flex gap-4">

      <div>
        <Skeleton width={400} height={500}/>
      </div>
      <div>
        <Skeleton width={400} height={500} />
      </div>
    </div>
    </div>
  );
};

export default ResultsSkeleton;
