import {
  MouseEvent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../app/hooks";
import { setItem } from "../../../utils/storage";
import { setActiveId } from "../../../app/Slices/tabSlice";

const TabContext = createContext<{
  id: string;
  setActiveTab: (e: MouseEvent<HTMLButtonElement>) => void;
}>({
  id: "",
  setActiveTab: () => {},
});

const Tab = ({
  tabs,
  defaultTab,
  getActiveTab,
  children,
  classes,
  activeClass,
}: {
  tabs: { value: string; id: string }[];
  defaultTab?: string;
  getActiveTab?: (activeTabId: string) => void;
  children: ReactNode;
  classes?: string;
  activeClass?: string;
}) => {
  const dispatch = useDispatch();
  const [id, setId] = useState<any>(defaultTab ?? tabs[0]?.id);
  const { selectedTutorDetails } = useAppSelector((state) => state.root.tutor);
  const { selectedStudentDetails } = useAppSelector(
    (state) => state.root.student
  );
  useEffect(() => {
    if (getActiveTab) {
      getActiveTab(id);
    }
  }, [getActiveTab, id]);

  useEffect(() => {
    if (tabs?.length > 0) {
      dispatch(setActiveId(tabs[0]?.id));
      setId(tabs[0]?.id);
    }
  }, [selectedTutorDetails._id]);
  useEffect(() => {
    if (tabs?.length > 0) {
      setId(tabs[0]?.id);
    }
  }, [selectedStudentDetails]);

  const setActiveTab = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      const id = e.currentTarget.id;
      dispatch(setActiveId(id));
      setItem("tabId", id);
      setId(id);
      if (getActiveTab) {
        getActiveTab(id);
      }
    },
    [dispatch, setItem, setId, getActiveTab]
  );
  const memoizedValue = useMemo(
    () => ({ id, setActiveTab }),
    [id, setActiveTab]
  );

  return (
    <TabContext.Provider value={memoizedValue}>
      <div>
        <div className="flex items-center py-4">
          {tabs.map((tab) => {
            return (
              <TabItem
                activeClass={activeClass}
                classes={classes}
                tabName={tab.value}
                key={tab.id}
                tabId={tab.id}
              />
            );
          })}
        </div>
        <div>{children}</div>
      </div>
    </TabContext.Provider>
  );
};

export default Tab;

const TabsBody = ({
  children,
  id,
}: {
  children: ReactNode;
  id: number | string;
}) => {
  const { id: activeTab } = useContext(TabContext);

  if (activeTab === id) {
    return <>{children}</>;
  }
  return null;
};

Tab.Body = TabsBody;

const TabItem = ({
  tabName,
  tabId,
  classes,
  activeClass,
}: {
  tabName: string;
  tabId: string;
  classes?: string;
  activeClass?: string;
}) => {
  const { id: activeTab, setActiveTab } = useContext(TabContext);
  return (
    <button
      className={`mr-10 ${
        tabId === activeTab ? activeClass : classes
      } cursor-pointer text-xl`}
      onClick={setActiveTab}
      id={tabId.toString()}
    >
      {tabName}
    </button>
  );
};
