import RenderUserAnswer from "./RenderUserAnswer";
import useAiFeedBack from "./useAiFeedback";

const RenderCell = ({ cell, questions }: any) => {
  const { extractQuestionId } = useAiFeedBack();
  let matchQuestion;
  if (cell.includes("{qId:")) {
    let extractedQId = extractQuestionId(cell);

    matchQuestion = questions?.find((item: any) => item.qId === extractedQId);
  }
  return (
    <p className="font-avenir">
      <RenderUserAnswer cell={cell} matchQuestion={matchQuestion} />
    </p>
  );
};

export default RenderCell;
