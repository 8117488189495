import { useNavigate } from "react-router";
import { useEffect } from "react";
import SidebarV1 from "../Sidebar";
import Header from "../Header";
import Loader from "../Common/CustomLoader";
import { useAppSelector } from "../../app/hooks";
import { resetAuthState, setEmail } from "../../app/Slices/authSlice";
import { useDispatch } from "react-redux";
import { resetStudentState } from "../../app/Slices/studentSlice";
import { resetTutorState } from "../../app/Slices/tutorSlice";
import { resetModalState } from "../../app/Slices/modalSlice";
import { resetWritingResults } from "../../app/Slices/writingResultsSlice";
import { resetSpeakingResults } from "../../app/Slices/speakingResultsSlice";
import { resetProfileData } from "../../app/Slices/profileSlice";

const AuthenticatedLayout = ({ children }: any) => {
  const navigate = useNavigate();
  const {isLoading} = useAppSelector(state => state.root.loader);
  const {isLoggedIn} = useAppSelector(state => state.root.auth)
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
      dispatch(setEmail(""));
      dispatch(resetStudentState());
      dispatch(resetTutorState());
      dispatch(resetModalState());
      dispatch(resetWritingResults());
      dispatch(resetSpeakingResults());
      dispatch(resetAuthState());
      dispatch(resetProfileData());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <div>
      <Loader isLoading={isLoading}/>
      <div className="flex h-screen bg-gray-100">
        <SidebarV1 />
        <div className="flex-1 flex flex-col overflow-x-hidden">
          <Header />
          <div className={`flex-1 flex flex-col bg-[#F9FBFF]`}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default AuthenticatedLayout;
