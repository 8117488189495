import axios from "axios";
import {
  setWritingError,
  setWritingFullTestPart1Data,
  setWritingFullTestPart2Data,
  setWritingFullTestScoreData,
} from "./app/Slices/writingResultsSlice";
import { setLoader } from "./app/Slices/loaderSlice";
import { ielts_url } from "./utils/apiConfig";

export const fetchWritingFullTestResults = async (
  testId: string,
  userId: string,
  dispatch?: any
) => {
  try {
    dispatch(setLoader(true));
    const res = await axios.get(
      `${ielts_url}/api/get-writing-test-score-tutor?test_id=${testId}&user_id=${userId}`
    );
    if (res.status === 200) {
      dispatch(setWritingError(false));
      dispatch(setLoader(false));
      dispatch(setWritingFullTestScoreData(res.data));
    }
  } catch (error) {
    dispatch(setWritingError(true));
    dispatch(setLoader(false));
    throw error;
  }
};

export const getWritingFulltestQuestionAnalysis = async (
  testId: string,
  testType: string,
  userId: string,
  dispatch?: any
) => {
  try {
    dispatch(setLoader(true));
    const res = await axios.get(
      `${ielts_url}/api/get-writing-question-analysis-tutor?type=${testType}&&user_id=${userId}&test_id=${testId}`
    );
    if (res.status === 200) {
      dispatch(setLoader(false));
      if (testType === "part1") {
        dispatch(setWritingFullTestPart1Data(res.data));
      } else if (testType === "part2") {
        dispatch(setWritingFullTestPart2Data(res.data));
      }
    }
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
};
