import { createSlice } from "@reduxjs/toolkit";
import { formatDateToCustomFormat } from "../../utils/reusableFunctions";
import { readingResultsApi } from "../../api/readingResultsApi";

interface ReadingResultsState {
  aiFeedback: {
    partType: string;
    paragraphTitle: string;
    paragraphContent: string;
    questionId: string;
    questionName: string;
    questionType: string;
    userAnswer: string;
    exactAnswer: string;
    isCorrect: boolean;
    isSelected: boolean;
  };
  readingResultsData: any;
  readingGraphData: any;
  readingAiFeedback: any;
  getReadingTestScorePartOne: any;
  getReadingTestScorePartTwo: any;
  getReadingTestScorePartThree: any;
  readingFullTestDetailedReport: any;
  currentPage: number;
  readingLastPage: number | null;
}

const initialState: ReadingResultsState = {
  aiFeedback: {
    partType: "",
    paragraphTitle: "",
    paragraphContent: "",
    questionId: "",
    questionName: "",
    questionType: "",
    userAnswer: "",
    exactAnswer: "",
    isCorrect: false,
    isSelected: false,
  },
  readingResultsData: [],
  readingGraphData: { scores: [], dates: [] },
  readingAiFeedback: {},
  getReadingTestScorePartOne: {},
  getReadingTestScorePartTwo: {},
  getReadingTestScorePartThree: {},
  readingFullTestDetailedReport: {},
  currentPage: 1,
  readingLastPage: null,
};
const readingResultsSlice = createSlice({
  name: "readingResults",
  initialState,
  reducers: {
    setAiFeedBack: (state, action) => {
      state.aiFeedback = {
        ...state.aiFeedback,
        ...action.payload,
      };
    },
    setReadingResultsData: (state, action) => {
      state.readingResultsData = action.payload;
    },
    setReadingAiFeedback: (state, action) => {
      state.readingAiFeedback = action.payload;
    },
    setReadingTestScorePartOne: (state, action) => {
      state.getReadingTestScorePartOne = action.payload;
    },
    setReadingTestScorePartTwo: (state, action) => {
      state.getReadingTestScorePartTwo = action.payload;
    },
    setReadingTestScorePartThree: (state, action) => {
      state.getReadingTestScorePartThree = action.payload;
    },
    setReadingFullTestDetailedReport: (state, action) => {
      state.readingFullTestDetailedReport = action.payload;
    },
    setReadingCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setReadingLastPage: (state, action) => {
      state.readingLastPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      readingResultsApi.endpoints.fetchReadingTestChart.matchFulfilled,
      (state, action) => {
        const transformedData = action.payload?.reduce(
          (result: any, item: any) => {
            result.scores.push(item.score);
            const dateObject = new Date(item.date);
            result.dates.push(formatDateToCustomFormat(dateObject));
            return result;
          },
          { scores: [] as string[], dates: [] as number[] }
        );
        state.readingGraphData = transformedData;
      }
    );
  },
});

export const {
  setReadingResultsData,
  setReadingAiFeedback,
  setReadingTestScorePartOne,
  setReadingTestScorePartTwo,
  setReadingTestScorePartThree,
  setReadingFullTestDetailedReport,
  setReadingCurrentPage,
  setReadingLastPage,
} = readingResultsSlice.actions;

export default readingResultsSlice.reducer;
