import { Fragment, ReactNode } from "react";
import { Menu, Transition } from "@headlessui/react";
import downArrow from "../../../assets/icons/down_arrow.png";
import Sorting from "./Sorting";

export type DropDownMenuType = {
  handleAscendingSorting?: () => void;
  handleDescendingSorting?: () => void;
  clearFilters?: () => void;
  children?: ReactNode;
  sortColumnNumber?: number;
  sortTypeNumber?: number;
};

const DropDownMenu = ({
  children,
  clearFilters,
  sortColumnNumber,
  sortTypeNumber,
  handleAscendingSorting,
  handleDescendingSorting,
}: DropDownMenuType) => {
  const defaultHandleAscendingSorting = () => {
    console.log("Default ascending sorting");
  };
  const defaultHandleDescendingSorting = () => {
    console.log("Default descending sorting");
  };
  const ascendingSortingHandler =
    handleAscendingSorting ?? defaultHandleAscendingSorting;
  const descendingSortingHandler =
    handleDescendingSorting ?? defaultHandleDescendingSorting;
  return (
    <Menu as="div" className="relative inline-block text-left z-10">
      <Menu.Button className="inline-flex w-full justify-center rounded-md px-4 py-2 text-sm font-medium text-white hover:bg-black/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
        <img
          style={{ width: 20, height: 20 }}
          src={downArrow}
          alt="arrow_icon"
        />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 p-4 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <button
                className={`${
                  active ? "text-[red]" : "text-gray-900"
                } group flex rounded-md items-center px-2 py-2 text-sm w-full`}
              >
                <div className="flex justify-end w-full">
                  <button
                    className="text-center"
                    disabled={!sortColumnNumber && !sortTypeNumber}
                    onClick={clearFilters}
                  >
                    Clear Filters
                  </button>
                </div>
              </button>
            )}
          </Menu.Item>
          {children}
          <hr />
          <Sorting
            handleAscendingSorting={ascendingSortingHandler}
            handleDescendingSorting={descendingSortingHandler}
            sortTypeNumber={sortTypeNumber ?? 0}
          />
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DropDownMenu;
