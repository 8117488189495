import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { LinerProgressBar } from "../../../Common/LinearProgressBar";
import useProgressBarStyles from "../../../Common/useProgressBarStyles";

const ReportCardDetails = (props: any) => {
  const { reportData, key } = props;
  const score = reportData?.score;
  const parameter = reportData?.parameter?.toLowerCase();
  return (
    <div className="flex flex-col flex-1 h-1/4 border border-[#E3E5E9] rounded-[8px] mt-[5px]">
      <div className="px-[16px] pt-[16px]">
        <LinerProgressBar score={score} parameter={parameter} />
      </div>
      <ul
        className="pt-[22px] px-[16px] ml-4 "
        style={{ listStyleType: "disc" }}
      >
        {props.reportData.description.map((item: any) => (
          <li key={key} className="my-2 text-[#737E93]">
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

const ReportFullTestCardDetails = ({ partDetails }: any) => {
  const { percentage, pathColor, trailColor, textColor, fraction } =
    useProgressBarStyles(partDetails);
  return (
    <>
      {!partDetails ? (
        <div className="m-auto my-5"></div>
      ) : (
        <div className="w-[66%]">
          <div className="flex flex-row flex-wrap">
            <div className="border border-[#E3E5E9] rounded-b-[8px] w-full md:h-[22rem] h-full flex flex-col">
              <div className="flex flex-col md:flex-row justify-between">
                <div className="flex flex-col md:mx-[32px] mx-1 my-[16px] w-1/2 justify-between h-full">
                  {partDetails.detailed_report &&
                    partDetails.detailed_report.length > 0 &&
                    partDetails.detailed_report.map((item: any) => {
                      return (
                        <LinerProgressBar
                          key={item.id}
                          score={item?.score}
                          parameter={item?.parameter?.toLowerCase()}
                        />
                      );
                    })}
                </div>
                <div className="md:w-[22.88rem] md:h-[18.75rem] w-[10rem] h-[10rem] rounded-[8px] flex justify-center items-center mb-2 ml-2 ">
                  <div className="w-[180px] h-[180px] ">
                    <CircularProgressbar
                      value={percentage}
                      text={`${fraction}/9`}
                      styles={buildStyles({
                        rotation: 1,
                        strokeLinecap: "round",
                        textSize: "20px",
                        pathTransitionDuration: 0.5,
                        pathColor: pathColor,
                        textColor: textColor,
                        trailColor: trailColor,
                      })}
                    />
                    <div className="md:text-[20px] text-[15px] font-Avenir font-medium text-[#3C424C] flex justify-center md:pt-[20px]">
                      Avg Band Score
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="my-[30px]">
            <div className="font-medium text-2xl font-Avenir text-[#292D35] ">
              Detailed Report
            </div>
            <div className="flex flex-row justify-between overflow-x-scroll lg:overflow-x-hidden md:overflow-x-hidden gap-2">
              {partDetails.detailed_report &&
                partDetails.detailed_report.length > 0 &&
                partDetails.detailed_report.map((item: any) => {
                  return <ReportCardDetails key={item.id} reportData={item} />;
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReportFullTestCardDetails;
